import React from 'react';
import { Alert, Card, Container, Row, Col } from 'react-bootstrap'

import "./corporate_profile.scss"

const Testimonials = () => {

	const reviews = [
		{
			customerName: "Martin Musal, Owner",
			company: "AMS Performance",
			companyLogo: `${ ( process.env.PUBLIC_URL ) }/testimonies/AMS-Logo1.png`,
			review: `
        EXEDY clutches and frictions have proven themselves over and over again in the harsh environment of the racing world.
        We trust them in our race cars, and recommend the same EXEDY products to our customers for daily performance use or all
        out racing.

      `
		}, {
			customerName: "Brian Lounsberry, C.E.O",
			company: "Motovicity Distribution",
			companyLogo: `${ ( process.env.PUBLIC_URL ) }/testimonies/Motovicity-Logo.png`,
			review: `
        EXEDY is a manufacturer in every sense of the word. Their OEM standards result in a product with one
        of the lowest failure rates we've ever seen. This is due to the fact that they make their products from scratch;
        taking raw metals and forging them using the highest industry standards. You simply can't buy a better product.
      `
		}, {
			customerName: "Justin \"JTP\" Pawlak, Formula D Drifter",
			company: "Formula D, Falken Mustang",
			companyLogo: `${ ( process.env.PUBLIC_URL ) }/testimonies/Justin_Pawlak_Falken.jpg`,
			review: `
        I'm extremely impressed by the quality and durability of my EXEDY clutch. We went from replacing clutches each
        event, with a previous clutch manufacturer, to running an off the shelf EXEDY clutch behind our 800hp Ford
        Racing/Roush Performance Supercharged Coyote engine all year, and still have nearly 50% life left in it.

        It's a testament to the quality and engineering that goes into each and every product EXEDY offers! I'm extremely
        excited to continue working with EXEDY and their supportive staff to prove the durability of their products in
        a race environment!
      `
		}, {
			customerName: "John Mummery, NMRA Racer",
			company: "NMRA, Super Stang Class",
			companyLogo: `${ ( process.env.PUBLIC_URL ) }/testimonies/John_Mummery_NMRA.png`,
			review: `
        EXEDY clutches give me the strength and durability I need to consistently get me to the finish line on race day.
        If you are looking for a clutch for your daily driver or your race car EXEDY clutches are simply the best.
      `
		}
	]

	return ( <Row className="justify-content-center">
		{
			reviews.map( ( review, idx ) => ( <Col
				sm={12}
				md={12}
				lg={review.review.length < 500
					? 4
					: 6}
				key={idx}
				className={`px-0 pr-3 mt-3 ${ review.review.length < 500
					? "order-1"
					: "order-3" }`}>
				<Card className="justify-content-center h-100 rounded-0 border-0">
					<Card.Body>
						<div className="font-weight-bold">{review.customerName}</div>
						<div className="mb-2">{review.company}</div>
						<div
							className="d-flex justify-content-center py-3 border-top border-bottom border-solid border-secondary">
							<img src={review.companyLogo} style={{
									maxWidth: "100%"
								}}/>
						</div>
						<div className="mt-3">
							<small>
								<q>
									{review.review}</q>
							</small>
						</div>
					</Card.Body>
				</Card>
			</Col> ) )
		}
	</Row> )
}

const CorporateProfile = () => {

	return ( <Container fluid="fluid">
		<Row className="justify-content-center">
			<Col md="9" sm="12">
				<div className="d-flex justify-content-center my-0">
					<div className="bg-transparent content py-5 px-3 px-lg-5">
						<div>
							<div className="h2 text-center text-uppercase text-dark px-0">
								The World Leader
							</div>
							<div
								className="h5 text-capitalize font-weight-normal text-secondary text-center">
								Leading OEM manufacturer in the world for Clutches and powertrain components.
							</div>
							<p className="mt-3">
								EXEDY Globalparts Corporation (USA) is a wholly owned subsidiary of the EXEDY
								Corporation of Japan, which was founded in 1923.
								<br/>
								<br/>
								Our advanced engineering and patented innovations have made us an undisputed
								world leader of performance sports and racing clutches. EXEDY’s manufacturing
								business and prominent brand name, Daikin Clutch, are known throughout the world
								for supplying quality powertrain products.
								<br/>
								<br/>
								Choose EXEDY for superior quality, reliability and safety.
							</p>
						</div>
						<div>
							<img
								src={`${ ( process.env.PUBLIC_URL ) }/exedy-world-map.png`}
								className="mw-100"/>
						</div>
						<div className="mt-0">
							<h2 className="text-center text-capitalize text-dark px-0">
								OEM AND AFTERMARKET
							</h2>
							<p className="mt-2 ">
								OEM AND AFTERMARKET EXEDY Globalparts Corporation has evolved from the Daikin
								Clutch Corporation, and our products still carry the famous Daikin winged logo.
								In 1967 The US business was established, and since 1988 all EXEDY/Daikin
								products have been manufactured in the US for both OEM and the Aftermarket.
								<br/>
								<br/>
								The EXEDY Group supplies Manual Clutches, Flywheels, and other advanced
								Powertrain Components, to all 11 vehicle manufacturers in Japan. In addition, we
								supply many vehicle manufacturers in the construction and machinery industries
								worldwide, including GM and Ford.
								<br/>
								<br/>
								EXEDY Globalparts (USA) now distributes all EXEDY products to the aftermarket
								for all of North, Central, and South America.
							</p>
						</div>
						<Alert variant="info" className="mt-3">
							<h4>Design</h4>
							<p className="">
								In addition to accumulated know-how as a clutch specialist, the latest CAD
								equipment has been introduced. By utilizing CATIA (3D Design) and FEA (Finite
								Element Analysis) engineering, the most appropriate designs and configurations
								are tailored to meet each customer’s requirements.
							</p>
						</Alert>
						<div className="text-center mt-5">
							<div className="mb-2 text-dark font-weight-bold ">
								<i>Over 65 Years of Excellence</i>
							</div>
							<img
								src={`${ ( process.env.PUBLIC_URL ) }/exedy-logo-lg.png`}
								width="100%"
								style={{
									maxWidth: "500px"
								}}/>
						</div>
						<div className="mt-3">
							<h2 className="text-center text-capitalize text-dark px-0">
								Quality Policy
							</h2>
							<p className="mt-2 ">
								EXEDY Globalparts Corporation believes that it is our social responsibility to
								continue providing the highest quality products and services that our customers
								around the world have come to expect.
							</p>
							<div>
								Course of Action:
							</div>
							<div className="text-dark">
								<div className="px-3 py-2 ">
									We will continuously enhance the quality management system to achieve the
									highest levels of customer satisfaction (CS); we will achieve zero defects and
									be the company where zero defects are standard.
								</div>
								<ol>
									<li>
										We value the voice of our customers and subsequent processes, endeavor to attain
										the highest level of quality from upstream processes, and respond with sincerity
										at all times.
									</li>
									<li>
										We always take action quickly based on Go & See and adherence to the rules.
									</li>
									<li>
										We always refine and improve our traceability capabilities.
									</li>
									<li>
										Zero Defect achievement through:
										<ul>
											<li>
												Control for progression through stage transition
											</li>
											<li>
												Full adherence to change management and handling of abnormal conditions
											</li>
											<li>
												Never-ending improvement through the QCC activity
											</li>
										</ul>
									</li>
								</ol>
							</div>
						</div>
						<Alert variant="info" className="mt-3">
							<h4>Inspection</h4>
							<p className="">
								EXEDY Multi Plate Clutches are assembled by hand with the finished products
								being individually assessed for clamp load and release characteristics. This
								extra effort and care provided by EXEDY ensures the highest quality product
								every time you choose an EXEDY product.
							</p>
						</Alert>
						<div>
							<Testimonials/>
						</div>
					</div>
				</div>
			</Col>
		</Row>
	</Container> )
}

export default CorporateProfile

import React, {useEffect, useState} from 'react';
import {Col, Container, Modal, Row} from 'react-bootstrap'
import {Link, useHistory, useLocation} from "react-router-dom"
//Components
import VehicleBrowse from "../widgets/vehicle_browser"
import VerifyFitment from "../widgets/verify_fitment"
//Hooks
import {useMakeModel, useModelSubModel, useYears} from '../../hooks/vehicles'
//Widgets
import {CustomDropdown} from "../widgets/custom_widgets.jsx"
//Icons
import {faSearch} from '@fortawesome/free-solid-svg-icons'

const ProductModal = ({make, model, subModel, onHide}) => {

  //Hooks
  const [getYears, years, loadingYears, errorYears] = useYears(make, model, subModel)
  const history = useHistory()

  useEffect(() => {
    getYears(make, model, subModel)
  }, [make, model, subModel])

  useEffect(() => {
    years.length == 1 && history.push(`/browse?make=${ make}&model=${ model}&subModel=${ subModel}&year=${ years[0] }`)
  }, [years])

  return ( <Modal
    show={!!( make && model && subModel && years && years.length > 1 )}
    onHide={onHide}
    size="xs">
    <Modal.Header closeButton="closeButton">
      <h3>{`Select ${ model} ${ subModel } Year`}</h3>
    </Modal.Header>
    <Modal.Body className="p-4">
      <CustomDropdown
        title="Year"
        size="lg"
        items={years}
        loading={loadingYears}
        error={errorYears}
        className="vehicle-selector"
        onSelect={(year) => {
          year && history.push(`/browse?make=${ make}&model=${ model}&subModel=${ subModel}&year=${ year }`)
        }}/>
    </Modal.Body>
  </Modal> )
}

const BrowseModels = ({make, modelList, browse}) => {

  const [{
    model,
    subModel
  }, setModelSubModel] = useState({})

  const history = useHistory()
  const [models, loading, error] = useModelSubModel(make, modelList)

  return ( <Container fluid="fluid" className="d-block p-0 size-to-viewport">
    <div className="d-flex justify-content-center my-0 py-5">
      <div className="content px-5">
        <Row className="align-items-end px-0">
          <Col className="px-0">
            <h3 className="text-left title text-dark mb-0">
              {make}
              &nbsp; Models
            </h3>
          </Col>
          <Col className="text-right">
            <Link to="/browse-vehicles" className="text-info">
              <small>View All Automative Makes</small>
            </Link>
          </Col>
        </Row>
        <Row className="mt-3 px-0">
          <Col xs="12" className="px-0">
            <VerifyFitment
              make={make}
              model={model}
              subModel={subModel}
              addApplication={(make, model, subModel, year)=> browse({make, model, subModel, year}, history)}
              actionTitle="Browse"
              faIcon={faSearch}/>
          </Col>
        </Row>
        <Row className="px-0 mt-3 mr-n4">
          <VehicleBrowse
            title="Models"
            subTitle="Sub Models"
            items={models}
            subItemsColumn="subModels"
            maxDisplayLength={models.length}
            loading={loading}
            error={error}
            onSubItemSelect={(model, subModel) => setModelSubModel({model: model.name, subModel})}/>
        </Row>
      </div>
    </div>
  </Container> )
}

const BrowseMakes = () => {

  const [makes, loading, error] = useMakeModel()

  return ( <Container fluid="fluid" className="d-block p-0 size-to-viewport">
    <div className="d-flex justify-content-center my-0 py-5">
      <div className="content px-5">
        <Row className="align-items-end">
          <Col className="p-0 text-center">
            <h3 className="title text-dark px-0">
              All Brand Offerings
            </h3>
            <small>
              EXEDY is not only a leader in providing high-quality clutches but we also
              manufacture various other vehicle parts.
            </small>
          </Col>
        </Row>
        <Row className="mt-4">
          <VehicleBrowse
            title="Makes"
            subTitle="Models"
            items={makes}
            subItemsColumn="models"
            maxDisplayLength={6}
            loading={loading}
            error={error}
            getLink={(make) => `/browse-vehicles/${ make.name }`}/>
        </Row>
      </div>
    </div>
  </Container> )
}

export {
  BrowseMakes,
  BrowseModels
}

import React from 'react';
import {
  Alert,
  Card,
  Container,
  Row,
  Col,
  ListGroup,
  ListGroupItem
} from 'react-bootstrap'

const Claims = ()=>{

  return (
    <Container fluid="fluid" className="vph-fill">
      <Row className="justify-content-center">
        <Col md="9" sm="12">
          <div className="d-flex justify-content-center my-0 py-5 px-3 px-lg-5">
            <div className="bg-white content p-4 p-lg-5">
              <div className="text-left">
                <h2 className="title text-dark px-0">
                  Claims and Warranty Procedures
                </h2>
                <hr/>
              </div>
              <div>
                <ListGroup variant="flush" className="pl-0 pr-0 pt-0 mx-0 mt-n2">
                  <ListGroupItem>
                    Any goods/products deemed by the Distributor to be  faulty must contact our Technical Support
                    Department at 800-346-6091 or by email: TechSupport@exedyusa.com
                    to obtain an Inspection  Request Form.
                    <br/>
                    <br/>
                    A completed Inspection Request Form  shall be returned for review,
                    if approved a RGA number  will be issued.

                    The goods/products being returned must be  sent to us freight prepaid.
                  </ListGroupItem>
                  <ListGroupItem>
                    No Claim Credit will be issued until the said goods/ products have been tested and deemed faulty by the  Company.
                  </ListGroupItem>
                  <ListGroupItem>
                    The Company will not pay any claim for goods/products  repaired by the Customer and/or claims sight unseen.
                  </ListGroupItem>
                  <ListGroupItem>
                    It is the responsibility of the Company’s Distributor to  advise their customer on all aspects of this warranty and/ or warranty procedures on any claim.
                  </ListGroupItem>
                  <ListGroupItem>
                    No Claim/Warranty will be given for NON-Kit OE  replacement components and/or hydraulic components.
                  </ListGroupItem>
                </ListGroup>
              </div>
            </div>
          </div>
        </Col>
      </Row>
    </Container>)
}

export default Claims
import React, { useState, useEffect } from 'react';
import {
	Nav,
	Button,
	FormControl,
	InputGroup,
	DropdownButton,
	Dropdown
} from 'react-bootstrap'
import Input from 'react-phone-number-input/input'

const CustomDropdown = ( {
	name,
	title,
	showTitle = false,
	value,
	items,
	itemDisplay,
	loading,
	error,
	onSelect,
	emptyMessage,
	drop = "down",
	className,
	size,
	variant = "outline-secondary"
} ) => {

	const dropdownRef = React.createRef()
	//State
	const [filter, setFilter] = useState( '' )
	const [show, setShow] = useState( false )

	items = items && items.filter(
		( item ) => typeof( item ) === "string"
			? item.toLowerCase().includes( filter.toLowerCase() )
			: item.toString().includes( filter )
	)

	itemDisplay = itemDisplay || ( item => item )

	//Effects
	useEffect( () => {
		if ( value == '' ) {
			process.nextTick( () => setFilter( '' ) )
		}
	} )

	//Handlers
	const handleSelect = onSelect
	const handleChange = ( event ) => {

		event.preventDefault()
		const dropDown = dropdownRef.current
		const dropDownMenu = dropDown.querySelector( ".dropdown-menu" )
		const filterValue = dropDown
			.querySelector( "input" )
			.value

		setShow( true )
		setFilter( filterValue )
	}

	const handleBlur = ( { event } ) => {

		let targetValue = ( dropdownRef.current.querySelector( "input" ).value || '' ).toLowerCase()
		if ( items && items.length == 1 ) {
			handleSelect( items[ 0 ] )
		} else if ( items && items.length > 1 ) {
			setFilter( '' )
			handleSelect( null )
		}
		setShow( false )
	}

	const disabled = items.length <= 1
	let dropDownItems = undefined
	if ( loading ) {
		var placeholder = `Loading ${ title }s`
		dropDownItems = ( <Dropdown.Item >
			<i>{placeholder}</i>
		</Dropdown.Item> )
	} else if ( items && items.length ) {
		var placeholder = title
		dropDownItems = items.map( ( item, itemIdx ) => ( <Dropdown.Item
			key={itemIdx}
			onMouseDown={() => {
				handleSelect( undefined )
				setShow( false )
				process.nextTick( () => handleSelect( item ) )
			}}>
			{itemDisplay( item )}
		</Dropdown.Item> ) )
	} else {
		var placeholder = title
		dropDownItems = ( <Dropdown.Item>{emptyMessage || "Empty"}</Dropdown.Item> )
	}

	return ( <div className={`flex-fill ${ className }`}>
		{
			showTitle && ( <div className="font-small">
				{title}
			</div> )
		}
		<Nav.Item onBlur={handleBlur}>
			<InputGroup size={size || "sm"} ref={dropdownRef}>
				<FormControl
					key={value}
					name={name}
					placeholder={placeholder}
					defaultValue={itemDisplay( value )}
					aria-label={title}
					aria-describedby="basic-addon2"
					onChange={handleChange}
					onFocus={() => !disabled && setShow( true )}/>
				<DropdownButton
					as={InputGroup.Append}
					alignRight={true}
					drop={drop}
					id="dropdown"
					disabled={disabled}
					title=''
					variant={variant}
					style={{
						position: 'relative',
						"maxHeight" : "500px",
						"scrollY" : "auto"
					}}
					show={show}
					onClick={() => !disabled && setShow( true )}>
					{dropDownItems}
				</DropdownButton >
			</InputGroup>
		</Nav.Item>
	</div> )
}

const CustomTextbox = ( {
	name,
	defaultValue,
	title,
	showTitle,
	onChange,
	as
} ) => {

	var timeoutHandler = undefined
	const handleChange = ( value ) => {
		timeoutHandler && clearTimeout( timeoutHandler )
		timeoutHandler = setTimeout( () => onChange( value ), 500 )
	}

	return ( <InputGroup size="sm" variant="outline-secondary">
		<FormControl
			name={name}
			defaultValue={defaultValue}
			as={as
				? as
				: undefined}
			className="border border-secondary"
			placeholder={title}
			aria-label={title}
			aria-describedby="basic-addon2"
			onChange={event => handleChange( event.currentTarget.value )}/>
	</InputGroup> )
}

const CustomPhoneNumberInput = ( { name, title, showTitle, onChange } ) => {
	return ( <InputGroup size="sm" variant="outline-secondary">
		<Input
			defaultCountry="US"
			name={name}
			className="border border-secondary form-control"
			placeholder={title}
			aria-label={title}
			aria-describedby="basic-addon2"
			onChange={phoneNumber => onChange( phoneNumber )}/>
	</InputGroup> )
}

const CustomTextboxButton = ( {
	title,
	onClick,
	children = ( <span>Button</span> )
} ) => {

	const inputRef = React.createRef()

	return ( <InputGroup size="sm" variant="outline-secondary">
		<FormControl
			ref={inputRef}
			placeholder={title}
			aria-label={title}
			aria-describedby="basic-addon2"
			onKeyPress={( event ) => event.charCode == 13 && onClick( inputRef.current.value )}/>
		<InputGroup.Append className="d-block">
			<Button variant="primary" onClick={() => onClick( inputRef.current.value )}>
				{children}
			</Button>
		</InputGroup.Append>
	</InputGroup> )
}

export {
	CustomTextbox,
	CustomDropdown,
	CustomTextboxButton,
	CustomPhoneNumberInput
}

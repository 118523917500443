import React, { useState, useEffect, useContext } from 'react';
import { Card, Container, Row, Col, Jumbotron } from 'react-bootstrap'
import { useLocation, useHistory, Link } from "react-router-dom"
import useRouterQuery from '../../hooks/query_router'

//Icons
import { faSearch } from '@fortawesome/free-solid-svg-icons'

//Components
import VerifyFitment from "../widgets/verify_fitment"

//Context
import { AppConfig } from "../../app.js"

//Hooks
import { useProductCategories } from '../../hooks/applications'
//Style
import "./product.scss"

const ProductTypesDisplay = ( { productList, loading } ) => {

	const appConfig = useContext( AppConfig )

	return productList.map( ( type, idx ) => {

		if ( productList && productList.length ) {
			return ( <Col xl="3" lg='4' md="6" sm="12" className="p-0 mb-3" key={idx}>
				<Card className="bg-white mr-3 h-100 rounded-0">
					<div className="d-flex flex-column h-100">
						<div className="text-left px-3 py-2">
							<div className="mb-n1">
								<Link
									to={`/products/product-types?productType=${ type.productType }`}
									className="text-dark subtitle">
									{type.productTitle}
								</Link>
							</div>
							<div className="text-secondary title ">{type.productCategory}</div>
						</div>
						<div
							className="d-flex flex-grow-1 flex-fill justify-content-center align-items-center pb-3 px-3">
							<img
								src={`${ appConfig.imageHost}/${ type.featuredProductImagePath }`}
								className="mh-100 mw-100"/>
						</div>
					</div>
				</Card>
			</Col> )
		} else {
			return ( <div>
				Loading Product Types
			</div> )
		}
	} )
}

const ProductTypeDisplay = ( { productType, browse } ) => {

	const history = useHistory()
	const [vehicle, setVehicle] = useState()

	const appConfig = useContext( AppConfig )

	console.log( "DESCRIPTION", productType.description )

	return ( <Col className="d-flex justify-content-center">
		<Jumbotron
			fluid="fluid"
			className="bg-transparent text-left mt-0 mb-3 py-3 px-0">
			<img
				className="pl-3 mx-auto float-lg-right"
				src={`${ appConfig.imageHost}/${ productType.featuredProductImagePath }`}
				style={{
					width: "300px",
					maxWidth: "100%"
				}}/>
			<h2 className="text-dark font-weight-bold">
				{productType.productTitle || productType.productType}
			</h2>
			<div
				className="description-segments"
				dangerouslySetInnerHTML={{
					__html: productType.description
				}}/>
			<div className="mt-3 mt-lg-5">
				<VerifyFitment
					addApplication={( make, model, subModel, year ) => browse( {
						make,
						model,
						subModel,
						year,
						productType: productType.productType
					}, history )}
					actionTitle={`Browse ${ productType.productCategory }s`}
					faIcon={faSearch}/>
			</div>
		</Jumbotron>
	</Col> )
}

const ViewAllProductTypes = ( { browse } ) => {

	const [productCategoriesList, loadingProductCategories, errorProductCategories] = useProductCategories()
	let { manufacturer, productType: productTypeName } = useRouterQuery()
	manufacturer = manufacturer || "EXEDY"

	const productList = productCategoriesList.filter( product => product.manufacturer == manufacturer )

	let display;
	if ( productTypeName && productList && productList.length ) {

		const productType = productCategoriesList.find( ( { productType } ) => productType == productTypeName )

		display = ( <Row>
			<ProductTypeDisplay
				browse={browse}
				productType={productType}
				loading={loadingProductCategories}/>
		</Row> )
	} else {
		display = ( <Row className="px-3 card-container justify-content-center">
			<ProductTypesDisplay browse={browse} productList={productList}/>
		</Row> )
	}

	return ( <Container fluid="fluid" className="d-block p-0 size-to-viewport">
		<div className="d-flex justify-content-center my-0 py-5">
			<div className="content px-3 px-lg-5">
				<Row className="align-items-end pb-2 mb-3">
					<Col className="text-right px-0">
						<div className="d-inline-block border-bottom px-2 px-lg-5 py-3 text-center">
							<h3 className="title text-dark mb-0">
								EXEDY
							</h3>
							<Link to="#" exact="true" className="title text-secondary font-weight-normal">
								View Products
							</Link>
						</div>
					</Col>
					<Col xs="auto" className="pr-0 pl-3"></Col>
					<Col className="text-left px-0">
						<div className="d-inline-block border-bottom px-2 px-lg-5 py-3 text-center">
							<h3 className="title text-dark mb-0">
								EXEDY OEM
							</h3>
							<div >
								<Link
									to="?manufacturer=EXEDY OEM"
									exact="true"
									className="title text-secondary font-weight-normal">
									View Products
								</Link>
							</div>
						</div>
					</Col>
				</Row>
				{display}
				<div className="small text-secondary">
					<div>QUALITY POLICY</div>
					<p>
						EXEDY Globalparts Corporation will continue to provide the highest quality
						levels of products and services that customers around the world appreciates. It
						is our social responsibility. We will continuously enhance the quality
						management system to achieve the highest levels of customer satisfaction (CS);
						we will achieve zero defects and be “the company that zero defects are a
						standard”. 1. We value the voice of our customers and subsequent processes,
						endeavor to attain the highest levels of quality from upstream process and
						respond with sincerity at all times. 2. We always take action with the speed of
						2-2-2 based on “Go & See” and adherence to the rules. 3. We always refine and
						improve our traceability capabilities. 4. Zero Defect achievement through: 5.
						Control for progression through stage transition 6. Full adherence to change
						management and handling of abnormal conditions 7. Never-ending improvement
						through the QCC activity
						<br/>
					</p>
					<div>DESIGN</div>
					<p>
						In addition to accumulated know-how as a clutch specialist, the latest CAD
						equipment has been introduced. By utilizing CATIA (3D Design) and FEA (Finite
						Element Analysis) engineering, the most appropriate designs and configurations
						are tailored to meet each customer’s requirements.
					</p>
					<div>INSPECTION</div>
					<p>
						EXEDY Multi Plate Clutches are assembled by hand with the finished products
						being individually assessed for clamp load and release characteristics. This
						extra effort and care provided by EXEDY ensures the highest quality product
						every time you choose an EXEDY product.
					</p>
				</div>
			</div>
		</div>
	</Container> )
}

export default ViewAllProductTypes

export {
	ViewAllProductTypes,
	ProductTypeDisplay,
	ProductTypesDisplay
}

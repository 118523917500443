import React, { useState, useEffect } from 'react';
import { Container, Row, Col } from 'react-bootstrap'

//Hooks
import { useProductApplications, useProductTypeDescriptions, useCart, useInventoryLevels } from '../../hooks/applications'
import useRouterQuery from '../../hooks/query_router'

//Components
import ProductModal from "../widgets/product_modal"
import ProductList from "./product_list.jsx"
import SideMenu from "./side_menu"

//Style
import "./browse.scss"

const flattenProductList = ( productApplications ) => {

	return productApplications.flatMap( ( { product, relatedProducts } ) => {

		const appList = [ product.partNumber ]
		if ( relatedProducts ) {
			appList.push( ...relatedProducts.map( ( { product: rProduct } ) => rProduct.partNumber ) )
		}
		return appList
	} )
}

const Browse = ( { parentRef, browseByVehicle } ) => {

	//State
	const [filter, setFilter] = useState( {} )
	const [sort, setSort] = useState()
	const [ {
			product,
			defaultTab
		}, setProductDisplay ] = useState( {} )
	const [maxResults, setMaxResults] = useState( 10 )

	//Hooks
	const query = useRouterQuery()
	const [getProductApplications, getProductApplicationsByPartNumber, productApplications, loading, error] = useProductApplications( query )
	const { addToCart, cart, removeFromCart, error: cartError, getProductQty } = useCart()

	return ( <Container fluid="fluid" className="d-flex justify-content-center pb-5">
		<Row className="content">
			<Col
				md="auto"
				sm="12"
				xs="12"
				className="side-menu-container mt-5 sticky-container">
				<SideMenu
					updateApplications={browseByVehicle}
					filter={filter}
					setFilter={setFilter}
					{...query}/>
			</Col>
			<Col className="mt-2 mt-lg-5 pt-1 product-list-container">
				<ProductList
					className="product-list"
					productApplications={productApplications}
					loading={loading}
					error={error}
					maxResults={maxResults}
					setProductDisplay={setProductDisplay}
					filter={filter}
					setFilter={setFilter}
					sort={sort}
					setSort={setSort}
					cart={cart}
					cartError={cartError}
					addToCart={addToCart}
					getProductQty={getProductQty}
					removeFromCart={removeFromCart}/>
			</Col>
		</Row>
	</Container> );
}

export default Browse;

import React, { useState, useEffect } from 'react';
import { Container, Card, Row, Col, Button } from 'react-bootstrap'
import { Link, useLocation } from "react-router-dom"
//Stripe
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';

//Hooks
import { useCart, useProductTypeDescriptions, useApplicationHistory, useAddApplicationToHistory, useCheckFitment } from '../../hooks/applications'

//Widgets

import CartSummary from "./cart_summary"
import VerifyFitment from "../widgets/verify_fitment"
import { BillingForm, ShippingForm } from "./forms"
import { ProductApplicationDisplay } from "../browse/product_list"
import { faCheckDouble } from '@fortawesome/free-solid-svg-icons'
import { LazyLoadImage } from 'react-lazy-load-image-component';
//Style
import 'react-lazy-load-image-component/src/effects/blur.css';
import "./shopping_cart.scss"

const stripePromise = loadStripe( process.env.REACT_APP_STRIPE_PK )

const validateRequireFields = ( obj, requiredFields ) => {
	return requiredFields.every( field => field in obj && obj[ field ] )
}

const ShoppingCart = () => {

	const [shippingInfo, setShippingInfo] = useState( {} )
	const [billingInfo, setBillingInfo] = useState( {} )
	const [slideIndex, setSlideIndex] = useState( 0 )
	const sliderRef = React.createRef()

	//Hooks
	const { cart, clearCart, addToCart, removeFromCart } = useCart()
	const [addApplication, applications, loadingApplications, errorApplications] = useAddApplicationToHistory()

	// const [fitmentList, loadingFitment, errorFitment] = useCheckFitment(
	// product.partNumber, applications )

	const previousSlide = setSlideIndex.bind( undefined, Math.min( 0, slideIndex - 1 ) )

	const proceedToCheckout = () => {
		if ( slideIndex == 0 ) {
			setSlideIndex( 1 )
		}
	}

	const processPaymentDisabled = !validateRequireFields( shippingInfo, [
		"firstName",
		"lastName",
		"email",
		"address",
		"city",
		"state",
		"zipcode",
		"phoneNumber"
	] )

	useEffect( () => {
		let sliderElement = sliderRef.current
		let slideWidth = sliderElement
			.querySelector( ".slide" )
			.clientWidth
		let scrollX = slideWidth * slideIndex
		sliderElement.scrollTo( scrollX, undefined )
		window.scrollTo( undefined, 0 )
	}, [ slideIndex ] )

	return ( <Container fluid="fluid" className="d-flex justify-content-center vph-fill">
		<Elements stripe={stripePromise}>
			<Row className="content my-3 my-lg-5 px-3 px-xs-3 px-sm-3 pl-md-3 pl-lg-3">
				<Col className="px-0">
					{
						cart && cart.items.length
							? ( <Row className="slider instant pb-2" ref={sliderRef}>
								<Col
									xs="12"
									className="slide"
									style={{
										display: slideIndex == 0
											? "initial"
											: "none"
									}}>
									<div>
										Please enter your vehicle's information, in order to check fitment.
									</div>
									<div className="font-weight-bold mt-2">
										<VerifyFitment
											addApplication={addApplication}
											actionTitle="Check Fitment"
											faIcon={faCheckDouble}/>
									</div>
									<ShoppingCartList
										cart={cart}
										addToCart={addToCart}
										removeFromCart={removeFromCart}
										applications={applications}/>
									<div>
										<Card className="mt-3 px-4 py-3 rounded-0">
											<Row className="align-items-center">
												<Col sm={12} xl={true} className="pr-0 ml-0">
													<small>
														By proceeding to checkout you're verifying that the products you're ordering are
														compatible with the appropriate applications.
													</small>
												</Col>
												<Col sm={12} xs="auto" xl="auto" className="px-3 mt-3 mt-xl-0">
													<Button variant="outline-info" size="sm" onClick={proceedToCheckout}>Proceed to Checkout</Button>
												</Col>
											</Row>
										</Card>
									</div>
								</Col>
								<Col
									xs="12"
									className="slide"
									style={{
										display: slideIndex == 1
											? "initial"
											: "none"
									}}>
									<Card className="p-4 rounded-0">
										<div className="title mb-2">Customer And Shipping Info</div>
										<ShippingForm setShippingInfo={setShippingInfo} shippingInfo={shippingInfo}/>
										<div className="title mt-2 mb-2">Billing Info</div>
										<BillingForm setBillingInfo={setBillingInfo} billingInfo={billingInfo}/>
									</Card>
								</Col>
							</Row> )
							: ( <div ref={sliderRef}>
								<Card className="slide rounded-0 p-3 text-danger">
									Cart Is Empty. Please Add Items to Your Cart to Proceed With Checkout.
								</Card>
							</div> )
					}
				</Col>
				<Col
					lg="4"
					className={`${ slideIndex == 1
						? "d-block"
						: "d-none d-lg-block" } side-menu-container sticky-container mt-2 mt-md-0 px-0 pl-md-3`}>
					<CartSummary
						cart={cart}
						clearCart={clearCart}
						shippingInfo={shippingInfo}
						billingInfo={billingInfo}
						slideIndex={slideIndex}
						processPaymentDisabled={processPaymentDisabled}
						previousSlide={previousSlide}/>
				</Col>
			</Row>
		</Elements>
	</Container> )
}

const FitmentProductApplicationDisplay = ( props ) => {

	const [fitmentList, loadingFitment, errorFitment] = useCheckFitment( props.product.partNumber, props.applications )
	const productApplication = {
		product: props.product,
		applications: fitmentList
	}

	const displayColumns = [ "fits", "make", "model", "subModel", "year" ]
	const displayMaxSize = 5

	return ( <ProductApplicationDisplay
		{...{productApplication, displayColumns,displayMaxSize, ...props}}/> )
}

const ShoppingCartList = ( { cart, applications, addToCart, removeFromCart } ) => {

	console.log("SHOPPING CART", cart, applications)

	return cart
		.items
		.map( ( item, idx ) => {
			return ( <div className="mt-3" key={idx}>
				<FitmentProductApplicationDisplay
					product={item.product}
					applications={applications}
					quantity={item.qty}
					cart={cart}
					addToCart={addToCart}
					removeFromCart={removeFromCart}/>
			</div> )
		} )
}

export default ShoppingCart;

import React from 'react';
import { Row, Col, ListGroup, ListGroupItem } from 'react-bootstrap'
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { Link } from "react-router-dom"
//Font Awesome
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import "./specs.scss"

const SpecStyle = {
	fontSize: "smaller"
}

const SpecDisplay = ( {
	className,
	title,
	partNumber,
	specs = [],
	center,
	discDiameter,
	splineCount,
	torqueCapacity,
	partImage
} ) => {

	return ( <div className={`specs ${ className }`}>
		<Row className="mx-0 py-1 bg-primary text-white">
			<div className="title px-3 text-left">
				{title}
			</div>
			<Col className="title px-3 text-right">
				<Link to={`/products/${ partNumber }`} className="text-white">View Product</Link>
			</Col>
		</Row>
		<div className="bg-transparent pb-2" style={{
				position: "relative"
			}}>
			<ListGroup variant="flush">
				<ListGroupItem>
					<Row>
						<Col xl="4" lg="5" xs={6}>
							Part Number:
						</Col>
						<Col xs="xs">
							{partNumber}
						</Col>
					</Row>
				</ListGroupItem>
				{
					Object
						.entries( specs )
						.map( ( [
							key, value
						], idx ) => ( <ListGroupItem key={idx}>
							<Row>
								<Col xl="4" lg="5" xs={6}>
									{key}:
								</Col>
								<Col xs="xs">
									{value}
								</Col>
							</Row>
						</ListGroupItem> ) )
				}
			</ListGroup>
			<div className="d-none d-lg-block stage1-image">
				<LazyLoadImage height="100%" src={partImage} effect="blur"/>
			</div>
		</div>
	</div> )
}

export default SpecDisplay

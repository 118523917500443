import React, { useState, useEffect, useContext } from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { Button, Row, Col, Jumbotron, Card } from 'react-bootstrap'
import { Link } from "react-router-dom"
//Context
import { AppConfig } from "../../../app.js"
//Iconography
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faInstagram } from '@fortawesome/free-brands-svg-icons'

//Influencers
import influencerBackground from "./images/influencer-background.png"
import influencerLogo from "./images/influencer-logo.png"
import influencer1 from "./images/sherwin_pacquette.png"
import influencer2 from "./images/joana-influencer.png"
import influencer3 from "./images/albrecht-influencer.png"

//Style
import "./influencer.scss"

const influencers = [
	{
		name: "Dan Albrecht",
		instagram: "albrechtracing",
		img: influencer3,
		quote: `Since aligning with Exedy I have experienced nothing but support and
        encouragement which has helped out my program immensely. The product
        has all of the durability of a racing clutch with the reliability and
        quality of an OEM clutch so to be able to represent them is truly an honor.`
	}, {
		name: "Joanna Gonzales",
		instagram: "prbeauty",
		img: influencer2,
		quote: `Part of being an influencer entails that I have responsibility to
        showcase certain companies and products. I am beyond thankful
        that Exedy has given me the opportunity to represent their brand.
        What I think is most incredible and my favorite part of working with
        them, is that they give me the opportunity to give back to my followers
        with giveaways. It makes me happy because without my supporters, I
        wouldn’t be where I am today. They have integrity about their products
        and care about the people that receive them.`
	}, {
		name: "Sherwin Pacquette",
		instagram: "that_grey__evo",
		img: influencer1,
		quote: `So grateful for the opportunity to be apart of exedy's influencer
         program it will not only give me the opportunity to reach so many
         people but it will also allow me to show people what support in the
        motorsport can do and how it can impact not only your "car life"
        but your day to day life as well... GAME ON I love it lets goo!!!!`
	}
]

const InfluencerSlide = ( { influencer } ) => {

	return ( <Card className="h-100 slide-content bg-white rounded-0 shadow-none border-0">
		<Card.Header
			className="img-container border-bottom border-secondary rounded-0"
			style={{
				backgroundImage: `url(${ influencer.img })`
			}}>
			<div
				className="instagram-tag"
				style={{
					position: "absolute",
					bottom: "-25px"
				}}>
				<div
					className="d-inline-block bg-instagram rounded-circle"
					style={{
						width: "50px",
						height: "50px",
						position: "relative",
						zIndex: 10
					}}>
					<FontAwesomeIcon
						icon={faInstagram}
						size="2x"
						className="text-white m-auto"
						style={{
							position: "relative",
							top: "9px",
							left: "11px"
						}}/>
				</div>
				<div
					className="d-inline-block ml-n3 px-4 py-1 bg-white text-secondary border border-secondary rounded-right-pill">
					@{influencer.instagram}
				</div>
			</div>
		</Card.Header>
		<Card.Body>
			<div className="py-3 text-secondary">
				<p className="quote">
					<i>{influencer.quote}</i>
				</p>
				<div className="text-right">
					- {influencer.name}
				</div>
			</div>
		</Card.Body>
	</Card> )
}

const InfluencerSlider = () => {

	//TODO make this a reusuable class
	//TODO make this sizeable Config
	const appConfig = useContext( AppConfig )
	const [influencerIndex, setInfluencerIndex] = useState( 0 )
	const maxSlideIndex = influencers.length - 1
	const sliderRef = React.createRef()

	const moveEventSlider = ( direction ) => {

		let index = direction > 0
			? ( influencerIndex + direction ) % maxSlideIndex
			: ( influencerIndex + direction + maxSlideIndex ) % maxSlideIndex

		setInfluencerIndex( index )
	}

	useEffect( () => {

		let sliderElement = sliderRef.current
		let slideWidth = sliderElement
			.querySelector( ".slide" )
			.clientWidth
		let slideMargin = sliderElement
			.querySelectorAll( ".slide-content" )[ influencerIndex ]
			.offsetLeft
		let scrollX = slideWidth * influencerIndex + slideMargin
		sliderElement.scrollTo( scrollX, undefined )
	}, [ influencerIndex, sliderRef ] )

	return ( <div className="d-flex justify-content-center py-0 background">
		<div className="content px-5">
			<div className="py-3 text-white">
				<Row className="px-0 ml-n3">
					<Col xs="12" lg="4" xl="3" className="pl-0 pr-2 pr-lg-3 pb-3 pb-lg-0">
						<div className="py-3 px-4 background-content h-100">
							<div className="d-flex justify-content-center">
								<div>
									<img
										src={influencerLogo}
										width="200px"
										style={{
											maxWidth: "100%"
										}}/>
								</div>
							</div>
							<div>
								<p>
									The EXEDY Influencer Program allows you to showcase EXEDY products you recommend
									to your followers.
									<br/>
									<br/>
									Promote your promo code, make it easier for your followers to shop your
									recommendations and earn money on purchases while doing it.
								</p>
								<div className="text-right">
									<Button
										variant="primary"
										className="px-3 rounded-left-pill rounded-right-pill"
										href={appConfig.influencerLink}>
										Learn More
									</Button>
								</div>
							</div>
						</div>
					</Col>
					<Col xs="12" lg="8" xl="9">
						<Row className="mr-n4 h-100 slider" ref={sliderRef}>
							{
								influencers.map( ( influencer, idx ) => ( <Col xs="12" md='6' xl='4' className="pr-3 m-0 p-0 slide" key={idx}>
									<div className="h-100">
										<InfluencerSlide influencer={influencer}/>
									</div>
								</Col> ) )
							}
						</Row>
					</Col>
				</Row>
			</div>
		</div>
	</div> )
}

export default InfluencerSlider

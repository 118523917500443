import React, { useState, useEffect, useRef } from 'react';
import {
	Form,
	Button,
	Container,
	Row,
	Col,
	Card,
	ListGroup,
	Overlay,
	Popover
} from 'react-bootstrap'
import { Link } from "react-router-dom"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBars, faEnvelope } from '@fortawesome/free-solid-svg-icons'
import { CustomDropdown, CustomTextbox, CustomPhoneNumberInput } from "../widgets/custom_widgets"
// Hooks
import { useSendCustomerMessage } from '../../hooks/orders'

const questionList = [
	{
		"question": "What is the torque capacity of your OE Replacement Clutch Kit?",
		"answer": "The OE Replacement Clutch Kits are rated for OEM power levels. If you require " +
				"a upgraded clutch kit you will need to refer to our performance product line.",
		"section": "Exedy OEM"
	}, {
		"question": "Do I need to resurface my flywheel?",
		"answer": "For EXEDY warranty purposes the flywheel must be resurfaced or replaced depend" +
				"ing on application.",
		"section": "Exedy OEM"
	}, {
		"question": "What is included with an EXEDY OE Replacement Clutch Kit?",
		"answer": "Most OE Replacement Clutch Kits include pressure plate assembly, clutch disc, " +
				"release bearing, pilot bearing or bushing ,alignment tool, lubricating grease " +
				"and general installation notes. Please contact our Technical department for sp" +
				"ecific kit components.",
		"section": "Exedy OEM"
	}, {
		"question": "What is the torque specification for the pressure plate to flywheel bolts?",
		"answer": "The pressure plate hold down bolt should be torqued to the factory specificati" +
				"on. Exedy recommends use of thread locker compound.",
		"section": "Exedy OEM"
	}, {
		"question": "The diaphragm fingers appear to be discolored, is this normal?",
		"answer": "The tips of the diaphragm spring go through an induction heat treating process" +
				" to increase strength followed by an oil quenching that creates this discolora" +
				"tion.",
		"section": "Exedy OEM"
	}, {
		"question": "Can I re-use my dual mass flywheel, because it cannot be resurfaced?",
		"answer": "Dual mass flywheels must be replaced with the clutch kit assembly. EXEDY offer" +
				"s single mass flywheel conversion kits for some applications.",
		"section": "Exedy OEM"
	}, {
		"question": "My OEM pressure plate was a self-adjusting type, the EXEDY OE Replacement Clut" +
				"ch Kit I received is not.",
		"answer": "EXEDY OE Replacement Clutch Kits have been designed for use in place of the fa" +
				"ctory self-adjusting where applicable.",
		"section": "Exedy OEM"
	}, {
		"question": "My EXEDY OE Replacement Clutch Kit came with Non-EXEDY parts?",
		"answer": "To ensure a full line of OE Replacement Clutch Kits we supply parts from OEM s" +
				"uppliers were EXEDY manufactured parts are not available.",
		"section": "Exedy OEM"
	}, {
		"question": "My vehicle came equipped with a flex type flywheel can this be machined?",
		"answer": "Flex type flywheels cannot be machined and must be replaced when servicing the" +
				" clutch.",
		"section": "Exedy OEM"
	}, {
		"question": "Will the OE Replacement Clutch Kit work with an OEM flywheel?",
		"answer": "Unless stated otherwise all EXEDY OE Replacement Clutch Kits are designed for " +
				"the OEM flywheel.",
		"section": "Exedy OEM"
	}, {
		"question": "What is the recommended break in process for EXEDY OE Replacement Clutch Kit?",
		"answer": "We recommend to allow 500 city mile or 1,000 highway miles for the break in pe" +
				"riod. During this period the vehicle should be driven easily with no high rpm " +
				"shifting, no quick shifting, and no launching.",
		"section": "Exedy OEM"
	}, {
		"question": "The new release bearing appears to be off center.",
		"answer": "Most release bearings are self centering and will center to the diaphragm spri" +
				"ng once installed.",
		"section": "Exedy OEM"
	}, {
		"question": "What is the warranty for EXEDY OE Replacement Clutch Kits?",
		"answer": "EXEDY OE Replacement Clutch Kits have a 12 month 20,000 mile warranty from dat" +
				"e of purchase against manufactured defects.",
		"section": "Exedy OEM"
	}, {
		"question": "Will the Stage 1 / 2 Sport Clutch Kits work with the OEM flywheel?",
		"answer": "Exedy Stage 1 / 2 Sport Clutch Kits are designed to work the OEM flywheel unle" +
				"ss stated otherwise. EXEDY also offer lightweight racing flywheels for many ap" +
				"plications.",
		"section": "Exedy"
	}, {
		"question": "Does the Stage 1 / 2 Sport Clutch Kits increase the pedal effort?",
		"answer": "Yes, typical pedal effort increase is 20-35% depending on application.",
		"section": "Exedy"
	}, {
		"question": "Will a HD clutch kit last longer than the non-HD variants?",
		"answer": "No, the HD clutch kits have a higher clamp load over the non-HD variants to al" +
				"low for an even higher torque holding capacity.",
		"section": "Exedy"
	}, {
		"question": "When taking off I hear a screeching noise during engagement after installing a" +
				"n EXEDY Stage 2 clutch kit.",
		"answer": "It is typical to hear a screeching noise after the break in period due to the " +
				"high metal compound of the friction material used with the stage 2 disc.",
		"section": "Exedy"
	}, {
		"question": "Are modifications required to install and EXEDY Stage 1 / 2 Sport Clutch Kits?",
		"answer": "No modifications are required.",
		"section": "Exedy"
	}, {
		"question": "Are replacement parts available for the EXEDY Stage 1 / 2 Sport Clutch Kits?",
		"answer": "Yes, replacement parts are available through any of our authorized EXEDY deale" +
				"rs.",
		"section": "Exedy"
	}, {
		"question": "Do the EXEDY single sports clutch kits require an upgraded release bearing?",
		"answer": "No, all EXEDY single sports kit include release and pilot bearings manufacture" +
				"d by OEM suppliers and are of the highest quality available.",
		"section": "Exedy"
	}, {
		"question": "What is the warranty for EXEDY Stage 1 / 2 Sport Clutch Kits?",
		"answer": "All EXEDY single sports clutch kits include a 60 day warranty from date of pur" +
				"chase against manufactured defects.",
		"section": "Exedy"
	}, {
		"question": "Is the Stage 1 clutch disc the same as the OEM replacement clutch disc?",
		"answer": "No, although they may appear similar, the Stage 1 Organic Disc uses a friction" +
				" material that is better suited for heat and the abuse of racing. Also the dam" +
				"pening assembly of the clutch disc is upgraded to handle an increase in torque" +
				" output as well as improved throttle response.",
		"section": "Exedy"
	}, {
		"question": "How does the EXEDY Stage 1 / 2 Sport Clutch Kits affect drivability?",
		"answer": "The Stage 1 Organic kits will drive similar to the OEM clutch but will have an" +
				" increased pedal effort. The Stage 2 Cerametallic clutch will have a more aggr" +
				"essive engagement and take off RPM may need to be increased for a smoother tak" +
				"e off.",
		"section": "Exedy"
	}, {
		"question": "How do I know when my EXEDY Stage 1 / 2 Sport Clutch disc is worn out?",
		"answer": "When the clutch disc is below the designed minimum thickness the disc should b" +
				"e discarded. Because this thickness varies depending on application please con" +
				"tact our technical department.",
		"section": "Exedy"
	}, {
		"question": "Can the EXEDY Hyper Series clutches be rebuilt?",
		"answer": "Yes, each component of the Hyper series clutch is available for purchase from " +
				"any authorized EXEDY distributor.",
		"section": "Exedy Hyper Series"
	}, {
		"question": "Does EXEDY offer a rebuild service?",
		"answer": "No, EXEDY does not offer a rebuilding service however we can inspect your clut" +
				"ch and advise on repairs needed.",
		"section": "Exedy Hyper Series"
	}, {
		"question": "Can you convert a Hyper Single to a Hyper Multi Plate?",
		"answer": "No, the Hyper single and Hyper multi plate clutches do not share any of the sa" +
				"me components.",
		"section": "Exedy Hyper Series"
	}, {
		"question": "Can you convert a Hyper Twin to a Hyper Triple?",
		"answer": "Yes, some Hyper twin plates can be converted to a Triple plate configuration d" +
				"epending on application. Please contact EXEDY's Technical department for furth" +
				"er information.",
		"section": "Exedy Hyper Series"
	}, {
		"question": "Can you machine the flywheel",
		"answer": "Yes all Hyper series flywheels are able to be machined, however the correct fl" +
				"ywheel step must be retained. Please contact EXEDY's technical department for " +
				"further information.",
		"section": "Exedy Hyper Series"
	}, {
		"question": "Do the Hyper Series clutch kits include release or pilot bearings/bushings?",
		"answer": "No, none of the EXEDY Hyper Series clutches include release or pilot bearings/" +
				"bushing. We offer Hyper Series Accessory Kits that included everything needed " +
				"for a complete installation.",
		"section": "Exedy Hyper Series"
	}, {
		"question": "Will an EXEDY Hyper Series clutch kit work with my OEM flywheel?",
		"answer": "No, the Hyper series clutch kits included a light weight one piece forged chro" +
				"moly flywheel that is included and is required for use.",
		"section": "Exedy Hyper Series"
	}, {
		"question": "What is the pedal effort like with a EXEDY Hyper Series clutch?",
		"answer": "Most EXEDY Hyper Series clutch kits will generally have similar or lighter ped" +
				"al effort than stock depending on application.",
		"section": "Exedy Hyper Series"
	}, {
		"question": "When the clutch is disengaged I hear a rattling noise, is this normal?",
		"answer": "The Hyper Twin and Triple plate clutch kits use a free floating pressure plate" +
				" and intermediate plate(s) design that will rattle when the clutch is disengag" +
				"ed.",
		"section": "Exedy Hyper Series"
	}, {
		"question": "Is there modifications required to install EXEDY Hyper Series?",
		"answer": "No, the Hyper Series clutch kits are designed to bolt in and use the factory a" +
				"ctuation system without modification. Excluding EXEDY Builder Series.",
		"section": "Exedy Hyper Series"
	}, {
		"question": "What is the warranty for EXEDY Hyper Series clutches ?",
		"answer": "Unfortunately EXEDY Hyper Series clutches do not carry any warranty due to the" +
				" intended use and nature of racing products.",
		"section": "Exedy Hyper Series"
	}
]

const EmailForm = () => {

	const emailInfoDefault = {
		name: undefined,
		email: undefined,
		phoneNumber: undefined,
		country: "United States",
		partNumber: undefined,
		distributor: undefined,
		issue: undefined,
		comment: undefined
	}

	const [emailInfo, setEmailInfo] = useState( emailInfoDefault )
	const [showMessage, setShowMessage] = useState( true )

	const { sendCustomerMessage, result, loading, error } = useSendCustomerMessage()
	const messageRef = useRef( null )
	const contactUsRef = useRef( null )

	const updateEmailInfo = ( field, value ) => setEmailInfo( ( previous ) => {
		let newState = Object.assign( {}, previous )
		newState[ field ] = value
		return newState
	} )

	const setShowMessageToFalse = () => setShowMessage( false )

	const issues = [
		"Vibration",
		"Noise",
		"Slipping",
		"Engagement",
		"Chatter",
		"Broken Part",
		"Other"
	]

	useEffect( () => {
		setShowMessage( true )
		if ( result == "success" ) {
			contactUsRef
				.current
				.reset()
		}

		process.nextTick( () => {
			window.removeEventListener( 'onclick', setShowMessageToFalse )
			window.onclick = setShowMessageToFalse
		} )
	}, [ result, error ] )

	return ( <Form name="ContactUsForm" ref={contactUsRef}>
		<Row>
			<Col xs="12" className="mt-1">
				<CustomTextbox
					title="Name (Required)"
					name="name"
					onChange={updateEmailInfo.bind( undefined, "name" )}/>
			</Col>
			<Col xs="12" className="mt-1">
				<CustomTextbox
					name="email"
					title="Email (Required)"
					onChange={updateEmailInfo.bind( undefined, "email" )}/>
			</Col>
			<Col xs="12" className="mt-1">
				<CustomPhoneNumberInput
					title="Phone Number (Required)"
					name="phoneNumber"
					onChange={updateEmailInfo.bind( undefined, "phoneNumber" )}/>
			</Col>
			<Col xs="12" className="mt-1">
				<CustomTextbox
					title="Country (Required)"
					name="country"
					onChange={updateEmailInfo.bind( undefined, "country" )}/>
			</Col>
			<Col xs="12" className="mt-1">
				<CustomTextbox
					title="Part Number (If Applicable)"
					name="partNumber"
					onChange={updateEmailInfo.bind( undefined, "partNumber" )}/>
			</Col>
			<Col xs="12" className="mt-1">
				<CustomTextbox
					title="Distributor (If Applicable)"
					name="distributor"
					onChange={updateEmailInfo.bind( undefined, "distributor" )}/>
			</Col>
			<Col xs="12" className="mt-1">
				<CustomDropdown
					title="Issue"
					name="issue"
					value={emailInfo.issue}
					items={issues}
					onSelect={updateEmailInfo.bind( undefined, "issue" )}
					emptyMessage="What Issue Are You Having?"
					className="vehicle-selector"/>
			</Col>
			<Col xs="12" className="mt-1">
				<CustomTextbox
					title="Comment"
					name="comment"
					as="textarea"
					onChange={updateEmailInfo.bind( undefined, "comment" )}/>
			</Col>
		</Row>
		<div className="mt-3" ref={messageRef}>
			<Overlay
				target={messageRef.current}
				show={showMessage && ( !!result || !!error )}
				placement="top">
				<Popover id="message-send" className="text-success">
					<Popover.Content>
						<span className="text-danger">{
								error
									? error
									: "Message Sent!"
							}</span>
					</Popover.Content>
				</Popover>
			</Overlay>
			<Button
				disabled={loading}
				variant="primary"
				size="sm"
				block="block"
				onClick={() => sendCustomerMessage( emailInfo )}>
				<span>Send Message</span>
				<span
					className="ml-2"
					style={{
						position: "relative",
						top: "1px"
					}}>
					<FontAwesomeIcon icon={faEnvelope}/>
				</span>
			</Button>
		</div>
	</Form> )
}

const FAQList = ( { currentSection, sections } ) => {

	return ( <div>
		{
			sections
				.filter(
					section => currentSection == "all"
						? true
						: section == currentSection
				)
				.map( ( section, idx ) => ( <div key={idx} className="mb-3">
					<div>
						<h3 className="title text-secondary pt-0 mt-n2">{
								currentSection == "all"
									? "faq"
									: currentSection
							}</h3>
					</div>
					<div>
						<ListGroup className="rounded-0">
							{
								questionList
									.filter( question => question.section == section )
									.map( ( question, questionIdx ) => ( <ListGroup.Item key={questionIdx} className="rounded-0">
										<Row className="bg-light">
											<Col sm="2" className="d-none d-sm-block text-dark">
												Question:
											</Col>
											<Col>
												{question.question}
											</Col>
										</Row>
										<Row>
											<Col sm="2" className="d-none d-sm-block">
												Answer:
											</Col>
											<Col>
												{question.answer}
											</Col>
										</Row>
									</ListGroup.Item> ) )
							}
						</ListGroup>
					</div>
				</div> ) )
		}
	</div> )
}

const FAQ = () => {

	const [currentSection, setCurrentSection] = useState( "Exedy OEM" )
	const sections = [ "Exedy", "Exedy OEM", "Exedy Hyper Series" ]

	return ( <Container fluid="fluid" className="d-flex justify-content-center pb-5">
		<Row className="content mt-5">
			<Col md="auto" sm="12" xs="12" className="px-3 px-lg-0 side-menu-container">
				<Card className="justify-content-start text-left pb-3 sticky rounded-0">
					<ListGroup variant="flush">
						<ListGroup.Item className="bg-light text-dark">
							<div className="title text-secondary">
								Product Categories
							</div>
							<div className={`mt-1 ${ currentSection == 'all' && 'font-weight-bold' }`}>
								<Link
									to="#"
									className="text-secondary"
									onClick={() => setCurrentSection( "all" )}>
									<span className="mr-2">
										<FontAwesomeIcon icon={faBars}/>
									</span>
									<span>Complete FAQ</span>
								</Link>
							</div>
							{
								sections.map( ( section, sectionIdx ) => ( <div
									className={`mt-1 ${ section == currentSection && 'font-weight-bold' }`}
									key={sectionIdx}>
									<Link
										to="#"
										className="text-secondary"
										onClick={() => setCurrentSection( section )}>
										<span className="mr-2">
											<FontAwesomeIcon icon={faBars}/>
										</span>
										<span>{section}&nbsp; FAQ</span>
									</Link>
								</div> ) )
							}
						</ListGroup.Item>
						<ListGroup.Item>
							<div className="title text-secondary">
								Contact Us
							</div>
							<small>
								If you have reviewed all our FAQs, Tech Videos and Fault Finding Guide, but
								still have a question, please feel free to call using the number listed below or
								email using the form below:
								<div className="mt-3">
									<b>
										Tech Support direct:
									</b>
									<span className="ml-2">(800) 346-6091</span>
								</div>
							</small>
							<div className="mt-2">
								<EmailForm/>
							</div>
						</ListGroup.Item>
					</ListGroup>
				</Card>
			</Col>
			<Col className="mt-3 mt-lg-0">
				<FAQList currentSection={currentSection} sections={sections}/>
			</Col>
		</Row>
	</Container> )
}

export default FAQ

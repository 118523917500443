import React, { useState, useEffect } from 'react';
import {
	Button,
	ButtonGroup,
	Row,
	Col,
	Carousel,
	Jumbotron
} from 'react-bootstrap'
import { LazyLoadImage } from 'react-lazy-load-image-component';
//Font Awesome
import { faShoppingCart } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

//Components
import SpecDisplay from '../spec-display/'

//Static
import "./jeep.scss"
import exploreImage from "./explore.png"
import newHorizonsImage from "./new-horizons.png"
import stage1Image from "./01800.png"
import stage2Image from "./01950.png"

const JeepSlide = ( { setBrowseDefaults } ) => {

	return ( <div className="carousel-img jeep-slide">
		<Row className="h-100">
			<Col xs="0" sm={true} md={true} lg="1"></Col>
			<Col
				xl="auto"
				className="d-flex h-100 h-lg-auto align-items-start align-items-lg-center pt-3 pt-md-5 pt-lg-0 campaign-content">
				<div className="px-3 px-lg-0">
					<LazyLoadImage width="100%" src={exploreImage}/>
					<LazyLoadImage width="100%" src={newHorizonsImage} className="mt-2"/>
					<SpecDisplay
						className="mx-lg-n3 mt-3"
						title="Stage 1 Clutch Kit"
						partNumber="01800"
						partImage={stage1Image}
						specs={{
							"Center" : "Sprung",
							"Disc Diameter" : "240mm",
							"Spline Count" : "23T",
							"Torque Capacity*" : "368 ft/lbs"
						}}/>
					<SpecDisplay
						className="mx-lg-n3 mt-3"
						title="Stage 2 Clutch Kit"
						partNumber="01950"
						partImage={stage2Image}
						specs={{
							"Center" : "Sprung",
							"Disc Diameter" : "240mm",
							"Spline Count" : "23T",
							"Torque Capacity*" : "396 ft/lbs"
						}}/>
				</div>
			</Col>
			<Col xs="0" sm={true} md={true} lg="0" xl="0"></Col>
		</Row>
	</div> )
}

export default { slide: JeepSlide, options: {} }
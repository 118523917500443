import React from 'react';
import { Card, Row, Col } from 'react-bootstrap'
import { Link } from "react-router-dom"
//Style

const titleCase = ( str ) => {

	return str
		.slice( 0, 1 )
		.toUpperCase() + str.slice( 1, str.length )
}

const VehicleBrowser = ( {
	title,
	subTitle,
	items,
	subItemsColumn,
	loading,
	error,
	getLink,
	onSubItemSelect,
	maxDisplayLength
} ) => {

	if ( items && items.length ) {

		return ( items.map( ( item, idx ) => ( <Col
			xl="3"
			lg='4'
			className="make-container py-1 pb-lg-3 px-0 pr-lg-3"
			key={idx}>
			<Card className="h-100 rounded-0">
				<Card.Body>
					<Row className="p-0 pb-2">
						<Col className="text-left">
							<h5 className="mb-n2 text-secondary title">{item.name}</h5>
							{
								item[ subItemsColumn ] && item[ subItemsColumn ].length > 6
									? ( <Link
										to={getLink
											? getLink( item )
											: "#"}
										className="subtitle">
										View All {item.name}
										&nbsp; {titleCase( subItemsColumn )}
									</Link> )
									: ( <div className="subtitle">
										{titleCase( subItemsColumn )}
									</div> )
							}
						</Col>
					</Row>
					<Row className="p-0">
						{
							item[ subItemsColumn ]
								.slice( 0, Math.min( maxDisplayLength, item[ subItemsColumn ].length ) )
								.map( ( subItem, subItemIdx ) => ( <Col xs="4" className="text-left mt-2" key={subItemIdx}>
									<Link
										to={getLink
											? getLink( item, subItem )
											: "#"}
										className="text-dark"
										onClick={() => onSubItemSelect && onSubItemSelect( item, subItem )}>
										{subItem}
									</Link>
								</Col> ) )
						}
					</Row>
				</Card.Body>
			</Card>
		</Col> ) ) )
	} else if ( loading ) {
		return ( <div>
			Loading {title}
		</div> )
	} else {
		return <div>Error</div>
	}
}

export default VehicleBrowser

import React from 'react';
import { Row, Col, ListGroup } from 'react-bootstrap'
//Icons
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimes, faCheck, faEdit, faExpand } from '@fortawesome/free-solid-svg-icons'

const ApplicationsList = ( { applications, maxLength, showNotes, columns=["make", "model", "subModel", "year", "engineSize"] } ) => {

	return ( <span className="application-list-container">
		<Row className="mx-0 title text-secondary">
		  { columns.map((column, idx)=>{
		  	if(column == "note"){
              return (
				  <Col key={idx} lg="4" className="d-flex px-0 align-items-end">{column}</Col>
              )
			}else if(column == "fits"){
			  //TODO CUSTOMIZE SIZE
              return (
				  <Col key={idx} className="d-flex px-2 align-items-end">{column}</Col>
              )
            }else{
		  		return (
		  			<Col key={idx} className="d-flex px-0 align-items-end">{column}</Col>
                )
			}
		  })}
		</Row>
		<ListGroup variant="flush" className="mx-0 px-0 py-0 my-0">
			{
				applications
					.slice( 0, Math.min( maxLength, applications.length ) )
					.map( ( application, applicationIdx ) => ( <span key={applicationIdx}>
						<ListGroup.Item className="px-0">
							<Row className="mx-0">
								{ columns.map((column, idx)=>{
                                  if(column == "note"){
                                    return (
										<Col key={idx} lg="4" className="px-0">{application[column]}</Col>
                                    )
                                  }else if (column == "fits"){
                                    return (
										<Col key={idx} className="px-2">
											<FontAwesomeIcon icon={!!application.fits ? faCheck : faTimes} className={application.fits ? "text-success":"text-danger"}/>
										</Col>
                                    )
								  }else{
                                    return (
										<Col key={idx} className="px-0">{application[column]}</Col>
                                    )
                                  }
                                })}
							</Row>
						</ListGroup.Item>
					</span> ) )
			}
		</ListGroup>
	</span> )
}

export default ApplicationsList

import React, { useContext } from 'react';
import {
	Badge,
	Button,
	Card,
	Col,
	Dropdown,
	DropdownButton,
	Row
} from 'react-bootstrap'
import { Link } from "react-router-dom"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
	faCartPlus,
	faList,
	faSortAmountDown,
	faSortAmountUp,
	faTimes,
	faCheck,
	faExclamationTriangle,
	faHourglass
} from '@fortawesome/free-solid-svg-icons'
import { useHistory } from "react-router-dom"

//Components
import ApplicationsList from "./applications_list"
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { ProductActions } from "../products/product-page"
//Context
import { AppConfig } from "../../app.js"
//Style
import 'react-lazy-load-image-component/src/effects/blur.css';

const dropDuplicates = ( uuid ) => ( applicationDict, application ) => {
	let key = application[ uuid ]
	applicationDict[ key ] = application
	return applicationDict
}

const sortProducts = ( column, desc ) => ( productA, productB ) => {
	let direction = desc
		? -1
		: 1

	if ( productA.product[ column ] == productB.product[ column ] ) {
		var comparison = 0
	} else if ( productA.product[ column ] < productB.product[ column ] ) {
		var comparison = -1
	} else {
		comparison = 1
	}

	return comparison * direction
}

//Maps
const IsInStock = ( { qty } ) => {

	let value = undefined
	let className = undefined
	let icon = undefined

	if ( qty == undefined ) {
		value = "Out of Stock"
		className = "text-danger"
		icon = faTimes
	} else if ( qty == NaN ) {
		value = "Computing..."
		className = "text-secondary"
		icon = faHourglass
	} else if ( qty == 0 ) {
		value = "Out of Stock"
		className = "text-danger"
		icon = faTimes
	} else if ( qty < 3 ) {
		value = "Limited Qty"
		className = "text-warning"
		icon = faExclamationTriangle
	} else {
		value = "In Stock"
		className = "text-success"
		icon = faCheck
	}

	return ( <div className={`text-capitalize`}>
		<FontAwesomeIcon icon={icon} className={`${ className }`}/>
		<span className="pl-1 font-weight-bold">{value}</span>
	</div> )
}

const ProductApplicationDisplay = ( {
	productApplication,
	cart,
	addToCart,
	removeFromCart,
	cartQty,
	badge,
	compatibility,
	orient = "horizontal",
	displayColumns,
	displayMaxSize = 3,
	cartError
} ) => {

	const history = useHistory()
	//Config
	const appConfig = useContext( AppConfig )

	const product = productApplication.product
	const applications = productApplication.applications || []

	const description = product.description
		? product
			.description
			.replace( /\;\s+/g, " - " )
		: ""

	const navigateToProductPage = ( event ) => {
		event.preventDefault()
		history.push( `/products/${ product.partNumber }` )
	}

	return ( <Col
		xs="12"
		lg={orient == 'vertical'
			? 6
			: 12}
		xl={orient == 'vertical'
			? 4
			: 12}
		className={`${ orient == "vertical"
			? "px-0"
			: "px-0" }`}>
		<Card
			className={`product-display rounded-0 ${ orient == "vertical"
				? "mb-3 mr-lg-3 vertical"
				: "" }`}
			onClick={navigateToProductPage}>
			<Row className="m-0 p-0">
				<Col
					xs="12"
					md="12"
					lg={orient == 'vertical'
						? 12
						: "auto"}
					className={`d-flex justify-content-center align-items-center card-img-container ${ orient == 'vertical'
						? "p-3 vertical"
						: "p-3" }`}>
					<div>
						{
							badge && <div className=" d-flex justify-content-start mb-3">
									<Badge variant="info">
										{badge}
									</Badge>
								</div>
						}
						<LazyLoadImage
							src={`${ appConfig.imageHost}/${ product.defaultImage }`}
							className="card-img"
							effect="blur"/>
					</div>
				</Col>
				<Col
					xs="12"
					md="12"
					lg={orient == 'vertical'
						? 12
						: true}
					className="p-0">
					<Card.Body>
						<div className="d-flex align-items-end justify-content-between mb-n2">
							<div>
								<Link
									to={`/products/product-types?productType=${ product.productType }`}
									className="subtitle"
									onClick={event => event.stopPropagation()}>
									<h5>{product.productTitle}</h5>
								</Link>
							</div>
							<div className="subtitle">
								<h5>{product && product && product.partNumber && ( product.partNumber )}</h5>
							</div>
						</div>
						<div className="text-secondary title">
							<h3>{product.productCategory}</h3>
						</div>
						{
							compatibility && compatibility.compatibilityType && ( <div>
								<span className="mr-1">{
										compatibility
											.compatibilityType
											.charAt( 0 ) + ( compatibility.compatibilityType.slice( 1 ) )
									}</span>
								<span className="text-secondary font-weight-bolder">{compatibility.product.partNumber}</span>
							</div> )
						}
						<div
							className={`${ orient == 'vertical'
								? "d-none"
								: "d-none d-lg-block" } mt-3 mb-2`}>
							<div className=" title text-secondary">
								Compatible Applications
							</div>
							<ApplicationsList
								applications={applications}
								maxLength={displayMaxSize}
								columns={displayColumns}/>
						</div>
						<div
							className={`${ orient == 'vertical'
								? "text-left"
								: "text-left text-lg-right" } text-info`}>
							<Link className="text-info" to="#" onClick={navigateToProductPage}>
								View Complete Buyer's Guide
							</Link>
						</div>
					</Card.Body>
				</Col>
				<Col
					xs="12"
					lg="auto"
					xl={orient == 'vertical'
						? 12
						: 2}
					className={`pb-3 pt-0 pt-lg-3 px-0 ${ orient == 'vertical'
						? 'vertical'
						: 'left-panel' }`}>
					<div className="text-left px-3">
						<IsInStock qty={productApplication.product.productInventory.qty}/>
						<div className="py-2">
							<h3>${product.productInventory && product.productInventory.retailPrice}</h3>
						</div>
					</div>
					<div
						className={`${ orient == 'vertical'
							? 'border-top pt-3'
							: 'pt-3 pt-lg-0' } actions w-100 mw-100 d-block d-lg-flex justify-content-center`}>
						<div>
							<ProductActions
								iconsOnly={true}
								product={productApplication.product}
								cartQty={cartQty}
								addToCart={addToCart}
								removeFromCart={removeFromCart}
								cartError={cartError}/>
						</div>
					</div>
				</Col>
			</Row>
		</Card>
	</Col> )
}

const ProductFilter = ( { productApplications, filter, setFilter, sort, setSort } ) => {

	let possibleFilterSet = {
		"manufacturer": "Manufacturer",
		"productGroup": "Product Category",
		'engineSize': "Engine Size",
		'discType': "Disc Type",
		'clutchDiscDiameter': 'Clutch Disc Diameter',
		'clutchDiscHubDiamter': "Clutch Disc Hub Diameter",
		'clutchDiscSplineQuantity': "Clutch Disc Spline Quantity"
	}

	let possibleSortSet = {
		"retailPrice": "Price",
		"productGroup": "Product Category"
	}

	let value_blacklist = [ "Not Applicable", "Unknown" ]

	let columnDict = productApplications
		.flatMap( ( item ) => [
			...item
				.applications
				.flatMap( Object.entries ),
			...Object.entries( item.product )
		] )
		.filter( ( [ column ] ) => Object.keys( possibleFilterSet ).includes( column ) )
		.reduce( ( columnHash, [ key, value ] ) => {
			if ( !( key in columnHash ) ) {
				columnHash[ key ] = new Set( [] )
			}

			value && !value_blacklist.includes( value ) && columnHash[ key ].add( value.trim() )
			return columnHash
		}, {} )

	let columnEntries = Object.entries( columnDict );
	const updateFilter = ( column, value ) => {

		filter[ column ] = new Set( [ value ] )
		setFilter( Object.assign( {}, filter ) )
	}

	const displayEntries = columnEntries
		.splice( 0, Math.min( 5, columnEntries.length ) )
		.filter( ( [ column, valueSet ] ) => valueSet.size > 1 || Object.keys( filter ).includes( column ) )

	const removeFilter = ( column ) => {

		filter[ column ] = null
		setFilter( Object.assign( {}, filter ) )
	}

	const updateSort = ( column ) => {

		if ( sort && sort.column == column && sort.desc ) {
			setSort( { column, desc: false } )
		} else if ( sort && sort.column == column && !sort.desc ) {
			setSort( undefined )
		} else {
			setSort( { column, desc: true } )
		}
	}

	const filterClassName = `${ displayEntries && displayEntries.length
		? ""
		: "d-none" } title text-secondary px-0`

	return ( <span style={{
			position: "relative"
		}}>
		<Row className="mx-0 mx-md-n3 px-0 pr-sm-3 pr-md-3 pr-lg-3">
			<Col sm="12" lg="9" className={filterClassName}>
				<Row className="px-0 mx-0">
					<Col lg="12" className="px-2">Filter Results By</Col>
					<Col xs="12" lg="9" className="px-0">
						<div className="d-flex flex-wrap mw-100">
							{
								displayEntries.map( ( [
									column, valueSet
								], idx ) => {

									let valueList = Array.from( valueSet )
									let title = possibleFilterSet[ column ]
									if ( column in filter && filter[ column ] ) {
										return ( <Button
											key={idx + "_exit"}
											variant="light"
											className="mt-1 mr-1 rounded-0 border-secondary"
											size="sm"
											onClick={() => removeFilter( column )}>
											<span className="mr-1">{title}:</span>
											<span className="mr-2">{filter[ column ]}</span>
											<FontAwesomeIcon icon={faTimes} color="#ccc" size="sm"/>
										</Button> )
									} else {
										return ( <DropdownButton
											title={title}
											variant="light"
											key={idx}
											className="mt-1 mr-1 alt filter-values-dropdown rounded-0 border-secondary"
											size="sm">
											{
												valueList
													.sort()
													.map( ( value, idx ) => ( <Dropdown.Item key={idx} onSelect={() => updateFilter( column, value )}>{value}</Dropdown.Item> ) )
											}
										</DropdownButton> )
									}
								} )
							}
						</div>
					</Col>
				</Row>
			</Col>
			<Col sm="12" lg="3" className="title text-secondary px-0">
				<hr className="d-lg-none my-1"/>
				<Row className="px-0 mx-0">
					<Col lg="12" className="px-2">Sort By</Col>
					<Col xs="12" lg="12" className="px-0">
						<div className="d-flex mw-100">
							{
								Object
									.entries( possibleSortSet )
									.map( ( [
										column, columnName
									], idx ) => ( <span key={idx}>
										<Button
											variant="light"
											className="mt-1 mr-1"
											size="sm"
											onClick={() => updateSort( column )}>
											<span className="mr-1">{columnName}</span>
											<span>
												{( sort && sort.column == column && sort.desc && ( <FontAwesomeIcon icon={faSortAmountDown} color="#999"/> ) ) || ( sort && sort.column == column && !sort.desc && ( <FontAwesomeIcon icon={faSortAmountUp} color="#999"/> ) )}
											</span>
										</Button>
									</span> ) )
							}
						</div>
					</Col>
				</Row>
			</Col>
		</Row>
	</span> )
}

const ProductList = ( {
	productApplications,
	loading,
	error,
	maxResults,
	setProductDisplay,
	filter = {},
	setFilter,
	sort,
	setSort,
	getProductQty,
	cart,
	cartError,
	addToCart,
	removeFromCart
} ) => {

	const columnFilter = ( productApplication ) => ( Object.entries( filter ).every(
		( [ column, allowedValueSet ] ) => allowedValueSet && allowedValueSet.size > 0
			? allowedValueSet.has( productApplication.product[ column ] ) || productApplication.applications.some( application => allowedValueSet.has( application[ column ] ) )
			: true
	) )

	if ( sort ) {
		var sorter = sortProducts( sort.column, sort.desc )
	} else {
		var sorter = undefined
	}

	if ( productApplications && productApplications.length ) {
		return ( <div>
			<a name="product-list"/>
			<ProductFilter
				productApplications={productApplications}
				filter={filter}
				setFilter={setFilter}
				sort={sort}
				setSort={setSort}/> {
				productApplications
					.filter( columnFilter )
					.sort( sorter )
					.map( ( productApplication, idx ) => ( <span key={idx}>
						<Row className="mt-3 px-3 pl-xs-3 px-sm-3 pl-md-0 pr-md-3 pl-lg-0 pr-lg-3">
							<ProductApplicationDisplay
								cartQty={getProductQty( productApplication.product.partNumber )}
								productApplication={productApplication}
								setProductDisplay={setProductDisplay}
								key={idx}
								cart={cart}
								addToCart={addToCart}
								removeFromCart={removeFromCart}
								cartError={cartError[ productApplication.product.partNumber ]}/>
						</Row>
						{
							( productApplication.relatedProducts && !!productApplication.relatedProducts.length ) && ( <span>
								<Row className="mt-3">
									<Col className="font-weight-bold px-xs-3 px-md-0">
										<span className="title text-secondary">Compatible Products</span>
									</Col>
								</Row>
								<Row
									className="mt-1 px-3 pl-xs-3 px-sm-3 pl-md-0 pr-md-3 pl-lg-0 pr-lg-3 mr-lg-n4 mr-xl-n4">
									{
										productApplication
											.relatedProducts
											.filter( columnFilter )
											.sort( sorter )
											.map( ( relatedProductApplication, relatedProductIdx ) => ( <ProductApplicationDisplay
												key={relatedProductIdx}
												orient="vertical"
												productApplication={relatedProductApplication}
												compatibility={{
													compatibilityType: relatedProductApplication.compatibilityType,
													product: productApplication.product
												}}
												setProductDisplay={setProductDisplay}
												cart={cart}
												addToCart={addToCart}
												removeFromCart={removeFromCart}/> ) )
									}
								</Row>
							</span> )
						}
					</span> ) )
			}
		</div> )
	} else if ( loading ) {
		return ( <div className="d-flex justify-content-center align-items-center h-100">
			<h3>Loading Results</h3>
		</div> )
	} else if ( error ) {
		return ( <div className="d-flex justify-content-center align-items-center h-100">
			<h3>Error Processing Query</h3>
			<div>{error}</div>
		</div> )
	} else {
		return ( <div
			className="d-flex justify-content-center align-items-top py-3 border-bottom">
			<div className="text-center">
				<h3>No Results Matched Your Query</h3>
				<div>
					Try Searching by Part Number or Enter Your Vehicle's Make, Model, SubModel and
					Year.
				</div>
			</div>
		</div> )
	}
}

export default ProductList
export {
	ProductApplicationDisplay,
	IsInStock
}

import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Switch, useHistory } from "react-router-dom";
import querystring from "querystring"
import {App} from './app.js'
import Main from './components/main/main.js'
import Browse from './components/browse/browse.js'
import { BrowseMakes, BrowseModels } from './components/browse_makes/browse_vehicles'
import ViewAllProductTypes from './components/products/product-types'
import ProductPage from "./components/products/product-page"
import CorporateProfile from './components/static/corporate_profile'
import FAQ from './components/static/faq'
import WarrantyPolicy from './components/static/warranty'
import Claims from "./components/static/claims"
import ShoppingCart from "./components/shopping_cart/shopping_cart"
import PaymentConfirmation from "./components/shopping_cart/payment_confirmation"
import OrderDetails from "./components/orders/orders"
import InfluencerPage from "./components/static/influencer-page"

const Routes = () => {

	//Hooks
	const [vehicle, setVehicle] = useState()
	const [searchText, setSearchText] = useState()

	const setSearchTextWrapper = ( searchText, history ) => {
		let searchTextEncoded = encodeURIComponent( searchText )
		process.nextTick( () => {
			history.push( `/browse?search=${ searchTextEncoded }` )
			setSearchText( searchText )
		} )
	}

	const browseByVehicle = ( queryParams, history ) => {

		history.push(`/browse?${querystring.stringify(queryParams)}`)
    }

	const MainComponent = ({history}) => ( <Main browseByVehicle={browseByVehicle} history={history}/> )
	const BrowseComponent = ( { match, searchText } ) => ( <Browse searchText={searchText} browseByVehicle={browseByVehicle}/> )
	const ShoppingCartComponent = () => ( <ShoppingCart/> )
	const BrowseModelsComponent = ( { match } ) => {

		const { make } = ( match && match.params ) || {}
		return ( <BrowseModels make={make} browse={browseByVehicle}/> )
	}

	const ProductPageComponent = ({match})=>{
		const {partNumber} = match.params
	  	return (<ProductPage partNumber={partNumber} browse={browseByVehicle}/>)
	}

	const ViewAllProductTypesComponent = ({match})=>{
		return <ViewAllProductTypes browse={browseByVehicle}/>
	}

	const PaymentConfirmationComponent = () => ( <PaymentConfirmation/> )
  	const OrderDetailsComponent = ()=> (<OrderDetails/>)

	return ( <Router>
		<Route path="/">
			<App searchText={searchText} setSearchText={setSearchTextWrapper}>
				<Switch>
					<Route path="/corporate-profile" component={CorporateProfile}/>
					<Route path="/faq" component={FAQ}/>
					<Route path="/warranty-policy" component={WarrantyPolicy}/>
					<Route path="/claims-and-warranty-procedures" component={Claims}/>
					<Route path="/influencers" component={InfluencerPage}/>
					<Route path="/browse" component={BrowseComponent}/>
					<Route path="/browse-vehicles/:make" component={BrowseModelsComponent}/>
					<Route path="/products/product-types" component={ViewAllProductTypesComponent}/>
					<Route path="/products/:partNumber" component={ProductPageComponent}/>
					<Route exact={true} path="/browse-vehicles" component={BrowseMakes}/>
					<Route path="/shopping-cart" component={ShoppingCartComponent}/>
					<Route path="/payment-confirmation" component={PaymentConfirmationComponent}/>
					<Route path="/order-details" component={OrderDetailsComponent}/>
					<Route exact={true} path="/" component={MainComponent}/>
				</Switch>
			</App>
		</Route>
	</Router> );
}

export default Routes;

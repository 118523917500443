import React, { useState, useEffect } from 'react';
import {
  Button,
  Container,
  Row,
  Col,
  Card,
  ListGroup
} from 'react-bootstrap'
import { LazyLoadImage } from 'react-lazy-load-image-component';
//
import titleImg from "../../images/modal/carousel-img-16.png"
import influecer1 from "../../images/influencer1.png"

const InfluencerPage = ()=>{


  return (
    <div>
      <Container fluid="fluid" className="d-flex justify-content-center pb-5">
        <div className="content py-5">
          <div className="text-center">
            <LazyLoadImage
              src={titleImg}
              width="300px"
              effect="blur"/>
          </div>
          <Row className="mt-5">
            <Col lg="7">
              <div className="title">
              Exedy Influencer Program
            </div>
              <p>
                The EXEDY Influencer Program allows you to showcase EXEDY products you recommend
                to your followers.  Promote your promo code, make it easier for your followers to shop
                your recommendations and earn money on purchases while doing it.
              </p>
              <div className="title">
                Who Qualifies For This Program
              </div>
              <p>
               Influencers across any industry can participate in this program.  Currently you must have a YouTube, Instagram, TikTok
                or Facebook account to qualify.  We look at the number of followers and other engagement metrics of your social media presence.
              </p>
              <div className="title">
                How Do I Get Started?
              </div>
              <p>
                Click on `Get Started` to apply for the program.  If you don't qualify right away, we encourage you to
                come back in the future as the eligibility requirements requirements may change over time.  ONce you've
                qualified you can start curating your promo code right away!
              </p>
              <Button variant="primary" className="mt-3 px-5 rounded-left-pill rounded-right-pill">
                Get Started
              </Button>
            </Col>
            <Col>
              <img
                src={influecer1}
                align="left"
                width="100%"
                className="mx-3"
                effect="blur"/>
            </Col>
          </Row>
        </div>
      </Container>
    </div>
  )
}

export default InfluencerPage
import React, { useState, useEffect } from 'react';
import {
	Button,
	Card,
	Container,
	Row,
	Col,
	Jumbotron
} from 'react-bootstrap'
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { Link } from "react-router-dom"
//Icons
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFacebook } from '@fortawesome/free-brands-svg-icons'
import { faInstagram } from '@fortawesome/free-brands-svg-icons'
import { faYoutube } from '@fortawesome/free-brands-svg-icons'
import { faTiktok } from '@fortawesome/free-brands-svg-icons'

import globalPartsLogo from "../../images/exedy-global-parts-logo.png"

//Modules
import VehicleFilter from "./vehicle_filter"
import CampaignSlider from "./campaigns/campaign-slider"
import InstagramWidget from "./instagram_widget"
import InfluencerSlider from "./influencer-slider/index"
import VehicleBrowser from "../widgets/vehicle_browser"
import { ProductTypesDisplay } from "../products/product-types"
//Queries
import { useMakeModel, useMakes } from '../../hooks/vehicles'
import { useProductCategories } from '../../hooks/applications'
//Utils
import { cherryPick } from "../../libs/utils"
//Style
import "./main.scss"

const MainContent = () => {

	const featuredList = [
		"Acura",
		"BMW",
		"Chevrolet",
		"Dodge",
		"Ford",
		"Honda",
		"Mazda",
		"Mercedes-Benz"
	]
	const [featuredMakes, loading, error] = useMakeModel( featuredList )
	const [productCategoriesList, loadingProductCategories, errorProductCategories] = useProductCategories()
	const featuredProductTypes = cherryPick( productCategoriesList, "productType", [ "hyper_single_clutch_fw", "stage_1_organic_clutch_fw", "stage_2_cerametallic_clutch_fw", "exedy_oem_clutch_kit_fw" ] )

	return ( <Container fluid="fluid" className="d-block p-0">
		<div className="d-flex justify-content-center my-0 pt-4">
			<div className="content px-5">
				<Row>
					<Col className="p-0 left ">
						<h3 className="title title-spaced text-dark px-0 mb-n1">
							Product Series
						</h3>
						<Row>
							<Col className="align-items-end">
								<small>
									EXEDY is not only a leader in providing high-quality clutches but we also
									manufacture various other vehicle parts.
								</small>
							</Col>
							<Col lg="auto" className="text-lg-right align-items-lg-end">
								<Link className="text-info section-link" to="/products/product-types">
									View All Product Series
								</Link>
							</Col>
						</Row>
					</Col>
				</Row>
				<Row className="mt-3 mr-n4 card-container">
					<ProductTypesDisplay
						productList={featuredProductTypes}
						loading={loadingProductCategories}/>
				</Row>
			</div>
		</div>
		<div className="d-flex justify-content-center my-0 pt-3">
			<div className="content px-5">
				<Row className="">
					<Col className="p-0 text-left">
						<h3 className="title title-spaced text-dark px-0 mb-n1">
							Vehicle Brand Offerings
						</h3>
						<Row>
							<Col className="align-items-end">
								<small>
									EXEDY provides products for various vehicle manufacturers.
								</small>
							</Col>
							<Col lg="auto" className="text-lg-right align-items-lg-end">
								<Link className="text-info section-link" to="/browse-vehicles">
									View All manufacturers
								</Link>
							</Col>
						</Row>
					</Col>
				</Row>
				<Row className="mt-3 mr-n4 makes-container">
					<VehicleBrowser
						title="Makes"
						subTitle="Models"
						items={featuredMakes}
						subItemsColumn="models"
						maxDisplayLength={6}
						loading={loading}
						getLink={( make ) => `/browse-vehicles/${ make.name }`}/>
				</Row>
			</div>
		</div>
		<InfluencerSlider/>
		<div className="d-flex mt-3 justify-content-center">
			<div className="content px-5">
				<Row>
					<Col className="p-0">
						<Jumbotron className="bg-transparent mt-0 pt-5 pb-0">
							<div className="d-flex flex-column justify-content-center">
								<div className="d-flex justify-content-center mb-4">
									<img src={globalPartsLogo} style={{
											maxWidth: '100%'
										}}/>
								</div>
								<p>
									EXEDY Globalparts Corporation (USA) is a wholly owned subsidiary of the EXEDY
									Corporation of Japan. EXEDY (Japan) was founded in 1923 and its clutch
									manufacturing business and prominent brand name Daikin Clutch are known
									throughout the world for supplying quality powertrain products.
									<br/>
									<br/>
									Our pursuit of perfection and the assurance of safety underlie our famed quality
									and reliability. Our advanced engineering and patented innovations have made us
									the leading OEM manufacturer in the world for Clutches and other powertrain
									components.
									<br/>
									<br/>
									EXEDY is the undisputed world leader of performance sports and racing clutches.
								</p>
								<div className="d-flex justify-content-start">
									<Link to="/corporate-profile" className="text-info section-link">
										read more
									</Link>
								</div>
							</div>
						</Jumbotron>
					</Col>
				</Row>
			</div>
		</div>
		<div className="d-flex justify-content-center">
			<div className="content px-0">
				<div className="d-flex p-3 justify-content-center">
					<div>
						<a href="https://www.facebook.com/exedy.usa">
							<FontAwesomeIcon icon={faFacebook} size="2x" className="text-info"/>
						</a>
					</div>
					<div className="mx-3">
						<a href="https://www.tiktok.com/@exedyusa?lang=en">
							<FontAwesomeIcon icon={faTiktok} size="2x" className="text-info"/>
						</a>
					</div>
					<div className="mx-3">
						<a href="https://www.youtube.com/channel/UCzNj52WTup-AH0lTqXGy5Ng">
							<FontAwesomeIcon icon={faYoutube} size="2x" className="text-info"/>
						</a>
					</div>
					<div>
						<a href="https://www.instagram.com/exedyusa/">
							<FontAwesomeIcon icon={faInstagram} size="2x" className="text-info"/>
						</a>
					</div>
				</div>
				<div className="text-center text-secondary font-weight-bold subtitle pb-5">Add Us On Social Media</div>
			</div>
		</div>
	</Container> )
}

const Main = ( { browseByVehicle, history } ) => {

	const campaignSliderRef = React.createRef()
	const [vehicleFilterDefaults, setVehicleFilterDefaults] = useState( {} )
	const [blink, setBlink] = useState( false )

	//Browse Defaults
	const setBrowseDefaults = ( defaults ) => {

		process.nextTick( () => {
			setVehicleFilterDefaults( defaults )
			setBlink( true )
		} )
		window.scrollTo( 0, campaignSliderRef.current.clientHeight + 60 )
	}

	const clearDefaults = () => {
		setVehicleFilterDefaults( {} )
	}

	return ( <Container fluid="fluid" className="main px-0">
		<CampaignSlider
			history={history}
			setBrowseDefaults={setBrowseDefaults}
			reference={campaignSliderRef}/>
		<VehicleFilter
			browseByVehicle={browseByVehicle}
			maxDropUpTargetElement={campaignSliderRef}
			hasDefaults={Object
				.keys( vehicleFilterDefaults )
				.length > 0}
			clearDefaults={clearDefaults}
			blink={blink}
			{...vehicleFilterDefaults}/>
		<MainContent/>
	</Container> );
}

export default Main;

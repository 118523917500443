import React, { useState, useEffect } from 'react';
import {
	Nav,
	Navbar,
    Row,
    Col,
	Form,
	Button,
	Container
} from 'react-bootstrap'
import { Link, useHistory } from "react-router-dom";
import gql from "graphql-tag";
import { useQuery, useLazyQuery } from "@apollo/react-hooks";

//Icons
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSearch, faTimes } from '@fortawesome/free-solid-svg-icons'

//Widgets
import { CustomDropdown } from "../widgets/custom_widgets"

//Queries
import { useMakeModel, useMakes, useModels, useSubModels, useYears } from '../../hooks/vehicles'

//styles
import "./vehicle_filter.scss"

const VehicleFilter = (
	{ browseByVehicle,
	  maxDropUpTargetElement,
	  defaultDrop = "up",
	  hasDefaults,
	  clearDefaults,
	  title="Select Your Vehicle",
      blink,
	  make:defaultMake,
      productType
    } ) => {

	//State
	//TODO refactor to use reducer instead!
	const [make, setMake] = useState( undefined )
	const [model, setModel] = useState( undefined )
	const [subModel, setSubModel] = useState( undefined )
	const [year, setYear] = useState( undefined )
  	const [drop, setDrop] = useState(defaultDrop)
  	const [opaque, setOpaque] = useState(false)
	const history = useHistory()

	//Hooks
	const [makes, loadingMakes, errorMakes] = useMakes()
	const [getModels, models, loadingModels, errorModels] = useModels( make )
	const [getSubModels, subModels, loadingSubModels, errorSubModels] = useSubModels( make, model )
	const [getYears, years, loadingYears, errorYears] = useYears( make, model, subModel )

  	//Effects
	const onScroll = ()=>{
		if (maxDropUpTargetElement && maxDropUpTargetElement.current && window.scrollY > maxDropUpTargetElement.current.clientHeight / 2){
		  setDrop("down")
		  // setOpaque(true)
		}else{
		  setDrop("up")
		  // setOpaque(false)
		}
	}

  	useEffect(()=>{
	  setMake(defaultMake)
	}, [defaultMake])

	useEffect( () => {
		if ( subModels.length == 1 ) {
			setSubModel( subModels[ 0 ] )
			getYears( make, model, subModels[ 0 ] )
		}
	}, [ subModels ] )

	useEffect( () => {
		if ( years.length == 1 ) {
			setYear( years[ 0 ] )
		}
	}, [ years ] )

  	useEffect(()=>{
	  window.addEventListener("scroll", onScroll);
	  //Remove Listener When Done
	  return ()=> window.removeEventListener("scroll", onScroll) && console.log("removed listener")
    })

	return ( <Navbar
		bg="dark"
		expand="lg"
		className={`justify-content-center py-0 vehicle-selection ${opaque ? "opaque" : ""}`}>
	  	{false && (
	  		<Link style={{position:"absolute", right:"15px"}} to="#" onClick={()=> clearDefaults()}>
				<FontAwesomeIcon icon={faTimes} color="#fff" className="mr-2"/>
				<span className="text-white">Clear Defaults</span>
			</Link>
		)}
		<Container className="nav-wrapper px-3">
			<Nav className="flex-fill">
				<Navbar.Text
					className={`vehicle-selection-title bg-danger text-white px-5 mx-4 text-center`}>
					<div className={`my-auto nowrap ${ blink ? "blink":""}`}>
					  {title}
					</div>
				</Navbar.Text>
				<Form>
				            <Row className="px-0">
				              <Col xs="12" lg className="px-0 py-1 py-lg-2 mx-0">
				                <CustomDropdown
				                  title="Make"
				                  value={make}
				                  items={makes}
				                  loading={loadingMakes}
				                  error={errorMakes}
				                  variant="dark"
				                  drop={drop}
				                  onSelect={( make ) => {
				                    setModel( undefined )
				                    setSubModel( undefined )
				                    setYear( undefined )
				                    setMake( make )
				                    getModels( make )
				                  }}
				                  className="mx-2"
				                  emptyMessage="No Results Matched Your Filter"/>
				              </Col>
				              <Col xs="12" lg className="px-0 py-1 py-lg-2 mx-0">
				                <CustomDropdown
				                  title="Model"
				                  value={model}
				                  items={models}
				                  loading={loadingModels}
				                  error={errorModels}
				                  variant="dark"
				                  drop={drop}
				                  onSelect={( model ) => {
				                    setYear( undefined )
				                    setSubModel( undefined )
				                    setModel( model )
				                    getSubModels( make, model )
				                  }}
				                  emptyMessage="Please Select Your Vehicle's Make"
				                  className="mx-2"/>
				              </Col>
				              <Col xs="12" lg className="px-0 py-1 py-lg-2 mx-0">
				                <CustomDropdown
				                  title="Sub Model"
				                  value={subModel}
				                  items={subModels}
				                  loading={loadingSubModels}
				                  error={errorSubModels}
				                  variant="dark"
				                  drop={drop}
				                  onSelect={( subModel ) => {
				                    setYear( undefined )
				                    setSubModel( subModel )
				                    getYears( make, model, subModel )
				                  }}
				                  emptyMessage="Please Select Your Vehicles's Model"
				                  className="mx-2"/>
				              </Col>
				              <Col xs="12" lg className="px-0 py-1 py-lg-2 mx-0">
				                <CustomDropdown
				                  title="Year"
				                  value={year}
				                  items={years}
				                  loading={loadingYears}
				                  error={errorYears}
				                  variant="dark"
				                  drop={drop}
				                  onSelect={( year ) => setYear( year )}
				                  emptyMessage="Please Select Your Vehicles's Sub Model"
				                  className="mx-2"/>
				              </Col>
				              <Col xs="12" lg="auto" className="px-2 py-1 py-lg-2 mx-0">
				                <Button
				                  variant="primary"
				                  className="px-3 py-1"
				                  size="sm"
				                  block="block"
				                  disabled={!( make && model && subModel && year )}
				                  onClick={browseByVehicle.bind( undefined, {
				                    make,
				                    model,
				                    subModel,
				                    year,
				                    productType
				                  }, history )}>
				                  <FontAwesomeIcon icon={faSearch} color="#fff"/>
				                </Button>
				              </Col>
				              <Col xs="12" lg="auto">
				                &nbsp;
				              </Col>
				            </Row>
				</Form>
			</Nav>
		</Container>
	</Navbar> )
}

export default VehicleFilter

import React, { useState, useEffect } from 'react';
import {
	Button,
	ButtonGroup,
	Row,
	Col,
	Carousel,
	Jumbotron
} from 'react-bootstrap'
import { Link } from "react-router-dom"
import { LazyLoadImage } from 'react-lazy-load-image-component';
//Font Awesome
import { faShoppingCart } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

//Slides
import MemorialSlide from "./memorial/index"
import HyperSeriesSlide from "./hyper-series/index"
import JeepSlide from "./jeep-horizons/index"
import StreetSeriesSlide from "./street-series-evo"
import StreetSeries2 from "./street-series"
import ContingencySlide from "./contingency"
import TypeRSlide from "./type-r"
import MaintenanceSlide from "./maintenance"

const CampaignSlider = ( { setBrowseDefaults, reference } ) => {

	// TODO replace street series with type-r campaign
	const slides = [ ContingencySlide, JeepSlide, HyperSeriesSlide, TypeRSlide ]

	return ( <div ref={reference}>
		<Carousel controls={false} fade={true} className="bg-dark">
			{
				slides.map( ( slide, idx ) => ( <Carousel.Item key={idx} {...slide.options}>
					{slide.slide( { setBrowseDefaults } )}
				</Carousel.Item> ) )
			}
		</Carousel>
	</div> )
}

export default CampaignSlider

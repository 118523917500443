import React, { useState, useEffect } from 'react';
import {
	Button,
	Card,
	Row,
	Col,
	Container,
	Jumbotron
} from 'react-bootstrap'
import { Link, useHistory } from "react-router-dom"
import useRouterQuery from '../../hooks/query_router'

export default() => {

	const { orderId, captured, nextFulfillmentDay } = useRouterQuery()
	const msg = captured
		? `
		Your order has been successfully placed. Once your order is successfully
		processed by our fufillment your payment will be processed
		and your order will be shipped to your address.
		`
		: `
		Your purchase completed successfully. An email will be sent to you to confirm
		your purchase.

		`
	console.log( "Order Id", orderId, captured )

	return ( <Container
		fluid="fluid"
		className="d-flex align-items-center justify-content-center vph-fill">
		<div className="d-flex h-100 justify-content-center">
			<Jumbotron style={{
					maxWidth: "800px"
				}}>
				<h2>Payment Successful</h2>
				<div>Order #: {orderId}</div>
				<div>
					<Link to={`/order-details?orderId=${ orderId }`} className="text-info">
						View Order Details
					</Link>
				</div>
				<p className="mt-3">{msg}</p>
				<Row className="justify-content-center">
					<Col xs="auto">
						<Link to="/" className="text-info">
							Home
						</Link>
					</Col>
					<Col xs="auto">
						<Link to="/browse" className="text-info">
							Browse
						</Link>
					</Col>
					<Col xs="auto">
						<Link to="/about" className="text-info">
							About Us
						</Link>
					</Col>
				</Row>
			</Jumbotron>
		</div>
	</Container> )
}

import React from 'react';
import {
	Nav,
	Navbar,
	FormControl,
	Form,
	Button,
	Container,
	ListGroup,
	Row,
	Col,
	Popover,
	OverlayTrigger,
	Overlay,
	InputGroup
} from 'react-bootstrap'
import { NavLink, Link, useHistory } from 'react-router-dom'
//Hooks
import { useCart } from '../../hooks/applications'
import useRouterQuery from '../../hooks/query_router'

//Icons
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSearch, faShoppingCart, faTrash, faCogs, faBackspace } from '@fortawesome/free-solid-svg-icons'
import './header.scss'

const cartIconRef = React.createRef()

const SettingsPopover = ( { clearCart } ) => ( <Popover id="popover-settings rounded-0">
	<Popover.Content className="p-3 rounded-0">
		<h5>Settings</h5>
		<ListGroup variant="flush">
			<ListGroup.Item className="px-0">
				<Link to="#" className="text-info" onClick={() => clearCart()}>
					<FontAwesomeIcon icon={faTrash} className="mr-2"/>
					<span>Clear Shopping Cart</span>
				</Link>
			</ListGroup.Item>
			<ListGroup.Item className="px-0">
				<Link to="#" className="text-info">
					<FontAwesomeIcon icon={faBackspace} className="mr-2"/>
					<span>Clear Application History</span>
				</Link>
			</ListGroup.Item>
		</ListGroup>
	</Popover.Content>
</Popover> )

const CartPopover = ( cart ) => ( <Popover id="popover-cart">

	<Popover.Content>
		<h5>Shopping Cart</h5>
		<ListGroup variant="flush">
			{
				cart
					.items
					.map( ( item, idx ) => {
						const productType = (
							item && item.product.productType
								? item.product.productType.toLowerCase().replace( /\s+/g, '_' )
								: ""
						)
						return ( <ListGroup.Item key={idx}>
							<Row>
								<Col>
									<div>{item.product.title}</div>
								</Col>
							</Row>
						</ListGroup.Item> )
					} )
			}
		</ListGroup>
	</Popover.Content>
</Popover> )

const Header = ( { logo, cartRef, setSearchText } ) => {

	//Refs
	const headerRef = React.createRef()
	//hooks
	const { cart, clearCart } = useCart( cartRef )
	const { search: searchText } = useRouterQuery()
	const history = useHistory()

	const searchTextRef = React.createRef()
	const cartTotal = cart
		.items
		.reduce( ( sum, item ) => sum + ( item.total ), 0 )
		.toFixed( 2 )

	return ( <Navbar
		collapseOnSelect={true}
		variant="dark"
		bg="black"
		expand="lg"
		className="main-header justify-content-center py-2 px-0"
		ref={headerRef}>
		<Container className="nav-wrapper">
			<Navbar.Brand className="px-3 px-lg-0">
				<img src={logo} alt="Exedy Logo" className="d-inline-block align-top logo"/>
			</Navbar.Brand>
			<Navbar.Toggle aria-controls="basic-navbar-nav"/>
			<Navbar.Collapse id="responsive-navbar-nav">
				<Nav justify="justify" className="d-flex pt-4 pt-xs-4 pt-lg-0 px-3 flex-fill">
					<NavLink to="/" exact={true} className='nav-link' activeClassName="active">
						Home
					</NavLink>
					<NavLink to="/corporate-profile" className='nav-link' activeClassName="active">
						Corporate Profile
					</NavLink>
					<NavLink
						to="/products/product-types"
						className='nav-link'
						activeClassName="active">
						Product Series
					</NavLink>
					<NavLink to="/browse-vehicles" className='nav-link' activeClassName="active">
						Vehicle Brands
					</NavLink>
					<NavLink to="/faq" className='nav-link' activeClassName="active">
						FAQ
					</NavLink>
					<Col
						xs={{
							order: "first"
						}}
						lg={{
							order: 0
						}}
						className="d-flex align-items-center px-0 pl-lg-4 pr-lg-2 mt-2 mt-lg-0">
						<Form className="w-100 p-0 m-0">
							<InputGroup size="sm">
								<FormControl
									ref={searchTextRef}
									type="search"
									placeholder="Search Make, Model and Year | Part Number"
									defaultValue={searchText}
									aria-label="Search Year, Make, Model"
									aria-describedby="basic-addon2"
									onKeyPress={( event ) => {
										if ( event.key == 'Enter' ) {
											event.preventDefault()
											const searchTextValue = searchTextRef.current.value
											searchTextValue && setSearchText( searchTextValue, history )
										}
									}}/>
								<InputGroup.Append className="d-block">
									<Button
										variant="primary"
										onClick={() => {
											const searchTextValue = searchTextRef.current.value
											searchTextValue && setSearchText( searchTextValue, history )
										}}>
										<FontAwesomeIcon icon={faSearch} color="#fff"/>
									</Button>
								</InputGroup.Append>
							</InputGroup>
						</Form>
					</Col>
					<Navbar.Text to="/browse" className='nav-link'>
						{`$${ cartTotal }`}
					</Navbar.Text>
					<NavLink to="/shopping-cart" className='nav-link'>
						<FontAwesomeIcon icon={faShoppingCart}/>
					</NavLink>
					<OverlayTrigger
						trigger="focus"
						placement="bottom"
						overlay={SettingsPopover( { clearCart } )}>
						<Link to="#" className='nav-link'>
							<FontAwesomeIcon icon={faCogs}/>
						</Link>
					</OverlayTrigger>
				</Nav>
			</Navbar.Collapse>
		</Container>
	</Navbar> );
}

export default Header;

import React, { useState, useEffect } from 'react';
import {
	Button,
	ButtonGroup,
	Row,
	Col,
	ListGroup,
	ListGroupItem,
	Carousel,
	Jumbotron
} from 'react-bootstrap'
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { Link } from "react-router-dom"
//Font Awesome
import { faShoppingCart } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

//Components
import SpecDisplay from '../spec-display/'

//Images
import stage1Image from "./08810.png"
import stage2Image from "./08957.png"
import noExecusesImage from "./no-excuses.png"
//Static
import "./type-r.scss"

const TypeRSlide = ( { setBrowseDefaults } ) => {

	return ( <div className="carousel-img type-r-slide">
		<Row className="h-100">
			<Col xs="0" sm={true} md={true} lg={true}></Col>
			<Col
				xl="auto"
				className="d-flex h-100 h-lg-auto align-items-start align-items-lg-center pt-3 pt-md-5 pt-lg-0 campaign-content">
				<div className="px-3 px-lg-0">
					<LazyLoadImage width="100%" src={noExecusesImage}/>
					<SpecDisplay
						className="mx-lg-n3 mt-3"
						title="Stage 1 Clutch Kit"
						partNumber="08806"
						partImage={stage1Image}
						specs={{
							"Spline Count" : "24T",
							"Torque Capacity*" : "193 ft/lbs",
							" " : "",
							"  " : ""
						}}/>
					<SpecDisplay
						className="mx-lg-n3 mt-3"
						title="Stage 2 Clutch Kit"
						partNumber="08900B"
						partImage={stage2Image}
						specs={{
							"Disc Type" : "Thin Disc",
							"Spline Count" : "24T",
							"Torque Capacity*" : "220 ft/lbs",
							" " : "",
							"  " : ""
						}}/>
				</div>
			</Col>
			<Col sm={true} md={true} lg="1"></Col>
		</Row>
	</div> )
}

export default { slide: TypeRSlide, options: {} }
import React, { useState, useEffect } from 'react';
import gql from "graphql-tag"
import { useQuery, useLazyQuery, useMutation } from "@apollo/react-hooks";

const GET_MAKES = gql `
  query getMakes {
    makes
  }
`

const GET_MODELS = gql `
  query getModels($make:String){
    models(make:$make)
  }
`

const GET_SUB_MODELS = gql `
  query getSubModels($make:String, $model:String){
    subModels(make:$make, model:$model)
  }
`

const GET_YEARS = gql `
  query getYears($make:String!, $model:String!, $subModel: String!){
    years(make:$make, model:$model, subModel:$subModel)
  }
`

const GET_MAKE_MODELS = gql `
  query getMakeModels($makes: [String!]){
      makeModels(makes:$makes){
        name
        models
      }
  }
`

const GET_MODEL_SUB_MODELS = gql `
  query modelSubModels($make: String!, $models:[String]){
    modelSubModels(make:$make, models:$models){
      name
      subModels
    }
  }
`

const GET_MODEL_YEARS = gql `
  query getModelYears($make: String!, $model:String!) {
    vehicles(make:$make, model:$model, dropDuplicates:["make", "model", "year"]){
      year
    }
  }
`

const useMakeModel = ( makes ) => {

	const { data, loading, error } = useQuery( GET_MAKE_MODELS, { variables: {
			makes
		} } )

	return [
		data
			? data.makeModels
			: [],
		loading,
		error
	]
}

const useModelSubModel = ( make, models ) => {

	const { data, loading, error } = useQuery( GET_MODEL_SUB_MODELS, {
		variables: {
			make,
			models
		}
	} )

	return [
		data
			? data.modelSubModels
			: [],
		loading,
		error
	]
}

const useMakes = () => {

	const { data, loading, error } = useQuery( GET_MAKES )

	return [
		data
			? data.makes
			: [],
		loading,
		error
	]
}

const useModels = ( make ) => {

	let [getModels, {
			loading,
			data,
			error
		}
	] = useLazyQuery( GET_MODELS, { variables: {
			make
		} } )

	const getModelsWrapper = ( make ) => {
		if ( make ) {
			getModels( { variables: {
					make
				} } )
		}
	}

	useEffect(()=>{
		getModelsWrapper(make)
	}, [make])

	let result = undefined
	if ( !make ) {
		result = []
	} else if ( data ) {
		result = data.models
	} else {
		result = []
	}

	return [ getModelsWrapper, result, loading, error ]
}

const useSubModels = ( make, model ) => {

	let [getSubModels, {
			loading,
			data,
			error
		}
	] = useLazyQuery( GET_SUB_MODELS, {
		variables: {
			make,
			model
		}
	} )

	const getSubModelsWrapper = ( make, model ) => {
		if ( make && model ) {
			getSubModels( {
				variables: {
					make,
					model
				}
			} )
		}
	}

    useEffect(()=>{
      getSubModelsWrapper(make, model)
    }, [make, model])

	let result = undefined
	if ( !( make && model ) ) {
		result = []
	} else if ( data ) {
		result = data.subModels
	} else {
		result = []
	}

	return [ getSubModelsWrapper, result, loading, error ]
}

const useYears = ( make, model, subModel ) => {

	let [getYears, {
			loading,
			data,
			error
		}
	] = useLazyQuery( GET_YEARS, {
		variables: {
			make,
			model,
			subModel
		}
	} )

	const getYearsWrapper = ( make, model, subModel ) => {
		console.log( "Retrieving Years..." )
		if ( make && model && subModel ) {
			getYears( {
				variables: {
					make,
					model,
					subModel
				}
			} )
		}
	}

    useEffect(()=>{
      getYearsWrapper(make, model, subModel)
    }, [make, model, subModel])

	let result = undefined
	if ( !( make && model && subModel ) ) {
		result = []
	} else if ( data ) {
		result = data.years
	} else {
		result = []
	}

	return [ getYearsWrapper, result, loading, error ]
}

export {
	useMakeModel,
	useModelSubModel,
	useMakes,
	useModels,
	useSubModels,
	useYears
}

import React, { useState, useEffect, useContext } from 'react';
import {
	Button,
	Card,
	Row,
	Col,
	Container,
	Jumbotron,
	Toast
} from 'react-bootstrap'
import { Link, useHistory } from "react-router-dom"
import useRouterQuery from '../../hooks/query_router'
//Config
import { AppConfig } from "../../app.js"
//Hooks
import { useOrder } from '../../hooks/orders'
//Widgets
import { LazyLoadImage } from 'react-lazy-load-image-component';

const formatMoney = ( value ) => value
	? `$${ value.toFixed( 2 ) }`
	: ""

const ShippingInfo = ( { orderSummary, userInfo } ) => {

	const trackingNumber = ( tracking ) => {
		if ( tracking ) {
			return ( <a
				href={`http://wwwapps.ups.com/WebTracking/track?track=yes&trackNums=${ tracking }`}
				className="text-info">
				1Z4509250360923955
			</a> )
		} else {
			return <div>PENDING</div>
		}
	}

	return ( <Card className="h-100 rounded-0">
		<Card.Body>
			<div className="title text-secondary">Order Number</div>
			<div>{orderSummary.orderNumber || 'PENDING'}</div>
			<div className="title text-secondary mt-3">Tracking Number</div>
			<div>{trackingNumber( orderSummary.tracking )}</div>
			<div className="title text-secondary mt-3">Shipping Address</div>
			<div>
				<span>{userInfo.firstName}</span>
				<span className="ml-1">{userInfo.lastName}</span>
			</div>
			<div>
				<span>{userInfo.address}</span>
				<span className="ml-1">{userInfo.address2}</span>
			</div>
			<div>
				<span>{userInfo.city},</span>
				<span className="ml-1">{userInfo.state}</span>
				<span className="ml-1">{userInfo.zipcode}</span>
			</div>
			<div className="title text-secondary mt-3">Shipping Method</div>
			<div>
				{orderSummary.serviceName}
			</div>
		</Card.Body>
	</Card> )
}

const CostSummary = ( { orderSummary, items } ) => {
	console.log( orderSummary )
	return ( <Card className="h-100 rounded-0">
		<Card.Body>
			<div className="title text-secondary">Order Summary</div>
			<div className="pb-3 border-bottom border-solid border-secondary">
				{
					items.map( ( item, idx ) => ( <Row key={idx}>
						<Col md="8">
							<div>({item.quantity}) {item.product.productTitle}</div>
							<div>
								<Link to={`/products/${ item.partNumber }`} className="text-info">View Product</Link>
							</div>
						</Col>
						<Col className="text-right">
							${
								item
									.retailPrice
									.toFixed( 2 ) * item.quantity
							}
						</Col>
					</Row> ) )
				}
			</div>
			<div className="mt-2">
				<Row className="mt-1 mx-0 pt-1 ">
					<Col className="px-0">
						Sub Total
					</Col>
					<Col xs="3" className="text-right px-0">
						{formatMoney( orderSummary.subTotal )}
					</Col>
				</Row>
				<Row className="mt-1 mx-0 pt-1 ">
					<Col className="px-0">
						Shipping Costs
					</Col>
					<Col xs="3" className="text-right px-0">
						{formatMoney( orderSummary.shippingRate )}
					</Col>
				</Row>
				{
					!!orderSummary.totalDiscount && (<Row className="mt-2 mx-0 pt-1 ">
						<Col className="px-0">
							Discount < /Col>
							<Col xs="3" className="text-right px-0">
								- {formatMoney( orderSummary.totalDiscount )}
							</Col >
						</Row>)}
				<Row className="mt-1 mx-0 pt-1 ">
					<Col className="px-0">
						Taxes
					</Col>
					<Col xs="3" className="text-right px-0">
						{formatMoney( orderSummary.taxes )}
					</Col>
				</Row>
			</div>
		</Card.Body>
		<Card.Footer>
			<Row className="mt-2 font-weight-bold">
				<Col>
					Total
				</Col>
				<Col xs="auto" className="text-right">
					{formatMoney( orderSummary.totalCost )}
				</Col>
			</Row>
		</Card.Footer>
	</Card> )
}

const ItemDetailsList = ( { orderSummary, items } ) => {

	const appConfig = useContext( AppConfig )

	return items.map( ( item, idx ) => {
		//TODO create helper for this
		return ( <Card key={idx} className="mt-3 rounded-0">
			<Card.Body>
				<Row>
					<Col md="3">
						<LazyLoadImage
							src={`${ appConfig.imageHost}/${ item.product.defaultImage }`}
							width="100%"
							className="card-img mh-100"
							effect="blur"/>
					</Col>
					<Col className="d-flex py-3 align-items-center">
						<div>
							<h4 className="m-0">
								<span>({item.quantity})</span>
								<span className="ml-1">{item.product.productTitle}</span>
							</h4>
							<div className="text-secondary">
								Part Number: {item.partNumber}
							</div>
							<div className="mt-3">
								<Link to={`/products/${ item.partNumber }`} className="text-info">View Product Details</Link>
							</div>
						</div>
					</Col>
					<Col md="3" className="d-flex py-3 align-items-center justify-content-center">
						<h3>${
								item
									.retailPrice
									.toFixed( 2 ) * item.quantity
							}</h3>
					</Col>
				</Row>
			</Card.Body>
		</Card> )
	} )
}

export default() => {

	const [showError, setShowError] = useState( false )
	const { orderId, captured } = useRouterQuery()
	const { orderSummary, loading, error, cancelOrder } = useOrder( orderId )

	const createdAt = orderSummary
		? new Date( orderSummary.createdAt ).toDateString()
		: undefined

	useEffect( () => {
		setShowError( !!error )
	}, [ error ] )

	console.log( orderSummary )

	return ( <Container fluid="fluid" className="vph-fill">
		<Toast
			className="error-toast"
			show={showError}
			onClose={() => setShowError( false )}
			delay={10000}
			autohide="autohide">
			<Toast.Header>
				<strong className="text-danger me-auto">Error</strong>
			</Toast.Header>
			<Toast.Body className="text-danger">{error && error.body}</Toast.Body>
		</Toast>
		<Row className="justify-content-center py-5">
			<Col md="9" sm="12">
				<div className="title text-secondary">
					Order Details
				</div>
				<Row className="mt-2">
					<Col xs="12" md="auto">
						{orderId.toUpperCase()}
					</Col>
					<Col xs="12" md={true} className="px-3 px-lg-0">
						<span className="text-warning font-weight-bold">{
								orderSummary.status && orderSummary
									.status
									.replace( "_", " " )
							}</span>
					</Col>
					<Col xs="12" md={true} className="text-left text-md-right">
						Ordered On {createdAt}
					</Col>
				</Row>
				<Row className="mt-3 mx-0">
					<Col md="5" className="px-0 mr-0 mr-md-3">
						<ShippingInfo userInfo={orderSummary.userInfo} orderSummary={orderSummary}/>
					</Col>
					<Col className="px-0 mt-3 mt-md-0">
						<CostSummary items={orderSummary.items} orderSummary={orderSummary}/>
					</Col>
				</Row>
				<div className="mt-3 px-0">
					<Card className="rounded-0">
						<Card.Body>
							<Row>
								<Col md="8" className="d-flex align-items-center">
									<small>
										By cancelling this order you acknowledge that you have thoroughly reviewed the
										cancellation policy.
									</small>
								</Col>
								<Col className="text-right">
									<Button
										variant="outline-danger"
										onClick={() => cancelOrder( orderSummary.orderId )}>
										Cancel Order
									</Button>
								</Col>
							</Row>
						</Card.Body>
					</Card>
				</div>
			</Col>
		</Row>
	</Container> )
}

import React, { useContext, useState, useEffect, useRef } from 'react';
import {
	Card,
	Carousel,
	Col,
	Container,
	ListGroup,
	ListGroupItem,
	Row,
	InputGroup,
	Button,
	Overlay,
	Popover
} from 'react-bootstrap'
import { NavLink, Link } from "react-router-dom"
//Hooks
import { useProductApplicationsDetails, useCheckFitment, useCart, useInventoryLevels, useRelatedProducts } from '../../hooks/applications'
//Components
import { ProductApplicationDisplay, IsInStock } from "../browse/product_list"
import ApplicationsList from "../browse/applications_list"
import VerifyFitment from "../widgets/verify_fitment"
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faShoppingCart, faTrash, faInfoCircle } from '@fortawesome/free-solid-svg-icons'
//Config
import { AppConfig } from "../../app.js"
//Icons
import { faCheckDouble } from '@fortawesome/free-solid-svg-icons'
//Style
import 'react-lazy-load-image-component/src/effects/blur.css';
import './product.scss'

const ProductPage = ( { partNumber } ) => {

	const [targetApplication, setTargetApplication] = useState()
	const [page, setPage] = useState( 0 )
	const [fitmentList, loadingFitment, errorFitment] = useCheckFitment( partNumber, [ targetApplication ] )
	const { getProductQty, addToCart, removeFromCart, error: cartError } = useCart()
	const [ {
			product,
			applications
		}, loadingProduct, errorProduct ] = useProductApplicationsDetails( partNumber )
	const fitmentMatches = fitmentList.find( fitment => fitment.fits )

	const verifyFitment = ( make, model, subModel, year ) => {
		setTargetApplication( { make, model, subModel, year } )
	}

	const cartQty = getProductQty( partNumber )

	const displayColumns = [
		"make",
		"model",
		"subModel",
		"year",
		"engineSize",
		"note"
	]

	const pageMapping = [ "Related Products", "Product Page" ]

	return ( <Container fluid="fluid" className="d-flex justify-content-center pb-5 ">
		<div className="content mt-3 mt-lg-5">
			<Row>
				<Col lg="10">
					<div className="mb-1">
						<FitmentResult fitmentList={fitmentList} matches={fitmentMatches}/>
					</div>
					<div>
						<VerifyFitment
							addApplication={verifyFitment}
							actionTitle="Check Fitment"
							faIcon={faCheckDouble}/>
					</div>
				</Col>
			</Row>
			<Row className="d-block d-lg-none">
				<Col className="title text-left mt-3 mb-n2">
					<Link to="#" className="text-info" onClick={() => setPage( page ^ 1 )}>
						{pageMapping[ page ]}
					</Link>
				</Col>
			</Row>
			<Row>
				<Col className={`${ page == 0
						? "d-block"
						: "d-none" } d-lg-block`}>
					<Row className="mt-3 mx-0 bg-white border border-secondary">
						<Col lg="5" className="p-3 px-4">
							<ImageSlider
								defaultImage={product.defaultImage}
								alternateImages={product.alternateImages}/>
						</Col>
						<Col className="d-flex flex-column px-0">
							<Row className="flex-fill mx-0">
								<Col xs="12" className="py-3">
									<NavLink
										to={`/products/product-types?productType=${ product.productType }`}
										className="title">
										<h2 className="text-secondary font-weight-light">{product.productTitle}</h2>
									</NavLink>
									<Row className="mt-n2">
										<Col className="subtitle">
											<h5>{partNumber}</h5>
										</Col>
										<Col xl="auto">
											<IsInStock qty={product.productInventory.qty}/>
										</Col>
									</Row>
									<div className="mt-2">
										<p
											dangerouslySetInnerHTML={{
												__html: product.description
											}}/>
									</div>
									<div className="mt-3">
										<h1 className="text-success m-0">${product.productInventory.retailPrice}</h1>
									</div>
								</Col>
							</Row>
							<Row className="mx-0">
								<Col xs="12" className="py-3 border-top border-solid">
									<ProductActions
										cartQty={cartQty}
										product={product}
										addToCart={addToCart}
										removeFromCart={removeFromCart}
										cartError={cartError[ partNumber ]}/>
								</Col>
							</Row>
						</Col>
					</Row>
					<Row className="mt-3">
						<Col lg="12">
							<Card className="bg-white p-3 rounded-0 shadow-none">
								<Card.Title className="px-3 pt-3 pb-0 mb-0 title text-secondary">
									Product Specifications
								</Card.Title>
								<Card.Body className="pt-0 px-2">
									<AttributeDisplay product={product}/>
								</Card.Body>
							</Card>
						</Col>
					</Row>
					<div className="mt-3">
						<Card className="p-3 bg-white rounded-0 shadow-none">
							<Card.Title className="px-3 pt-3 mb-0 title text-secondary">
								Compatible Applications
							</Card.Title>
							<Card.Body>
								<ApplicationsList
									applications={applications}
									maxLength={applications.length}
									showNotes={true}
									columns={displayColumns}/>
							</Card.Body>
						</Card>
					</div>
				</Col>
				<Col
					md="auto"
					sm="12"
					xs="12"
					className={`${ page == 1
						? "d-block"
						: "d-none" } d-lg-block p-3 pl-md-0 pr-xl-0`}>
					<SideMenu
						product={product}
						addToCart={addToCart}
						removeFromCart={removeFromCart}
						cartError={cartError}
						getProductQty={getProductQty}/>
				</Col>
			</Row>
		</div>
	</Container> )
}

const ProductActions = ( {
	cartQty,
	product,
	addToCart,
	removeFromCart,
	cartError,
	iconsOnly = false
} ) => {

	const [showError, setShowError] = useState( true )

	const addToCartRef = useRef( null )
	return ( <Row className="w-100">
		<Col
			className="mx-0 px-2 d-flex justify-content-center align-items-center text-center">
			<div>
				<span
					className="product-action clickable"
					to={"#"}
					disabled={true}
					onClick={( event ) => {
						event.stopPropagation()
						addToCart( product, 1 )
					}}>
					<div ref={addToCartRef}/>
					<div style={{
							position: "relative"
						}}>
						{
							!!cartQty && ( <div
								className="border border-3 border-white rounded-circle text-white font-weight-bold bg-primary cart-qty">
								<span className="qty-number">{cartQty}</span>
							</div> )
						}
						<FontAwesomeIcon icon={faShoppingCart} className="text-primary" size="lg"/>
					</div>
					<div
						className={`${ iconsOnly
							? "d-block d-lg-none"
							: "d-block" } text-primary subtitle`}>Add To Cart</div>
					<Overlay
						target={addToCartRef.current}
						show={showError && !!cartError}
						placement="top">
						<Popover id="error" className="text-danger">
							<Popover.Content>
								<span className="text-danger">{cartError}</span>
							</Popover.Content>
						</Popover>
					</Overlay>
				</span>
			</div>
		</Col>
		<Col
			className="mx-0 px-2 d-flex justify-content-center align-items-center text-center">
			<div>
				<NavLink
					to={`/products/product-types?productType=${ product.productType }`}
					onClick={event => event.stopPropagation()}
					className="product-action text-info">
					<FontAwesomeIcon icon={faInfoCircle} size="lg"/>
					<div
						className={`${ iconsOnly
							? "d-block d-lg-none"
							: "d-block" } text-info subtitle`}>Product Info</div>
				</NavLink>
			</div>
		</Col>
		<Col
			className="mx-0 px-2 d-flex justify-content-center align-items-center text-center">
			<div>
				<NavLink
					to={"#"}
					className="product-action"
					onClick={( event ) => {
						event.stopPropagation()
						removeFromCart( product )
					}}>
					<FontAwesomeIcon icon={faTrash} className="text-secondary" size="lg"/>
					<div
						className={`${ iconsOnly
							? "d-block d-lg-none"
							: "d-block" } text-secondary subtitle`}>Remove</div>
				</NavLink>
			</div>
		</Col>
		<Col xl={3}></Col>
	</Row> )
}

const compatibilityGroupsTitleMapping = {
	"requires": "Required By",
	"fits": "Compatible With",
	"includes": "Included In",
	"not_included": "Not Included"
}

const SideMenu = ( { product, addToCart, removeFromCart, cartError, getProductQty } ) => {

	const [relatedProducts, loadingRelatedProducts, errorRelatedProducts] = useRelatedProducts( product.partNumber )
	const compatibilityGroups = relatedProducts.reduce( ( groups, relatedProduct ) => {
		const group = relatedProduct.compatibilityType
		if ( !( group in groups ) ) {
			groups[ group ] = []
		}

		groups[ group ].push( relatedProduct )
		return groups
	}, {} )

	if ( relatedProducts && relatedProducts.length ) {
		var compatibilityGroupsDisplay = Object
			.entries( compatibilityGroups )
			.map( ( [
				groupName, products
			], groupIdx ) => ( <div key={groupIdx} className="mb-3 px-0 py-0 side-menu-container sticky">
				<div className="title text-secondary">{compatibilityGroupsTitleMapping[ groupName ]}</div>
				<ListGroup variant="flush" className="px-0 border border-secondary">
					{
						products.map( ( {
							product: relatedProduct,
							compatibilityType
						}, idx ) => ( <ListGroupItem key={idx}>
							<ProductDisplayMini
								product={relatedProduct}
								addToCart={addToCart}
								removeFromCart={removeFromCart}
								cartError={cartError[ relatedProduct.partNumber ]}
								cartQty={getProductQty( relatedProduct.partNumber )}/>
						</ListGroupItem> ) )
					}
				</ListGroup>
			</div> ) )
	} else if ( loadingRelatedProducts ) {
		var compatibilityGroupsDisplay = ( <div
			className="mb-3 px-3 py-3 bg-white side-menu-container sticky border border-secondary">Loading Compatible Products</div> )
	} else if ( errorRelatedProducts ) {
		console.log( "Error Loading Related Products", errorRelatedProducts )
		var compatibilityGroupsDisplay = ( <div
			className="text-danger mb-3 px-3 py-3 bg-white side-menu-container sticky border border-secondary">Error Loading Compatible Products</div> )
	} else {
		var compatibilityGroupsDisplay = ( <div>No Related Products</div> )
	}

	return compatibilityGroupsDisplay
}

const ProductDisplayMini = ( { product, addToCart, removeFromCart, cartQty, cartError } ) => {

	const appConfig = useContext( AppConfig )

	return ( <Row className="px-0 mx-n3">
		<Col lg={5} className="px-0 d-flex align-items-center">
			<img src={`${ appConfig.imageHost}/${ product.defaultImage }`} width="100%"/>
		</Col>
		<Col>
			<div className="title spacing-0 mb-n2">
				<NavLink to={`/products/product-types?productType=${ product.productType }`}>
					<span className="text-secondary font-weight-normal">{product.productTitle}</span>
				</NavLink>
			</div>
			<div className="subtitle">
				Product# {product.partNumber}
			</div>
			<div className="pt-2">
				<NavLink to={`/products/${ product.partNumber }`}>
					<span className="text-info subtitle font-weight-normal">View Product</span>
				</NavLink>
			</div>
			<div className="pt-0 text-success font-weight-bold">
				${product.productInventory.retailPrice}
			</div>
			<div className="mt-2 pt-2 px-2">
				<ProductActions
					cartQty={cartQty}
					product={product}
					addToCart={addToCart}
					removeFromCart={removeFromCart}
					cartError={cartError}
					iconsOnly={true}/>
			</div>
		</Col>
	</Row> )
}

const FitmentResult = ( { matches, fitmentList } ) => {

	if ( matches ) {
		return ( <div className="text-success">This product fits your specified vehicle.</div> )
	} else if ( !matches && fitmentList && fitmentList.length ) {
		return ( <div className="text-danger">This product does not fit your specified vehicle.</div> )
	} else {
		return ( <div className="text-dark">Please enter your vehicle's information, in order to check fitment.</div> )
	}
}

const ImageSlider = ( { defaultImage, alternateImages } ) => {

	//TODO Make this a widget
	const appConfig = useContext( AppConfig )
	const imageList = [
		defaultImage, ...( alternateImages || [] ).map( image => image.location )
	]

	return ( <Carousel
		controls={false}
		indicators={true}
		fade={true}
		className="product-carousel w-100 h-100 bg-white"
		timeout="10000"
		style={{
			minHeight: "300px"
		}}>
		{
			imageList.map( ( image, idx ) => ( <Carousel.Item key={idx} className="w-100 h-100">
				<div
					style={{
						width: "100%",
						height: "100%",
						background: `url(${ appConfig.imageHost}/${ image }) center center/contain no-repeat`
					}}></div>
			</Carousel.Item> ) )
		}
	</Carousel> )
}

const AttributeDisplay = ( { product } ) => {

	let attributes = product.attributes || []

	attributes = attributes.concat( [
		{
			"attributeType": "Package Dimensions",
			"attribute": `${ product.width} x ${ product.height} x ${ product.height } inches`
		}, {
			"attributeType": "Package Weight",
			"attribute": product.weight
		}
	] )

	const attributeMap = attributes.reduce( ( groups, { attributeType, attribute } ) => {
		if ( !( attributeType in groups ) ) {
			groups[ attributeType ] = []
		}

		groups[ attributeType ].push( attribute )
		return groups
	}, {} )

	return ( <ListGroup variant="flush">
		{
			Object
				.entries( attributeMap )
				.map( ( [
					attributeType, attribute
				], idx ) => ( <ListGroupItem key={idx}>
					<Row>
						<Col lg="4" className="d-flex align-items-center px-0 text-capitalize">
							{attributeType.replaceAll( "_", " " )}
						</Col>
						<Col className="px-0 text-capitalize">
							{attribute.map( ( value, attrIdx ) => ( <div key={attrIdx}>{value}</div> ) )}
						</Col>
					</Row>
				</ListGroupItem> ) )
		}
	</ListGroup> )
}

export default ProductPage
export {
	ProductActions
}

import React, { useState, useEffect } from 'react';
import {
	Card,
	ListGroup,
	Row,
	Button,
	Col,
	InputGroup,
	FormControl,
	DropdownButton,
	Dropdown
} from 'react-bootstrap'
import { useQuery, useLazyQuery } from "@apollo/react-hooks";
import { Link, useHistory } from "react-router-dom"

//Widgets
import { CustomDropdown, CustomTextboxButton } from "../widgets/custom_widgets.jsx"
//Icons
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSearch, faCaretDown } from '@fortawesome/free-solid-svg-icons'
//Queries
import { useMakeModel, useMakes, useModels, useSubModels, useYears } from '../../hooks/vehicles'

import { useProductCategories, useApplicationHistory } from '../../hooks/applications'

const getUniqueValues = ( list ) => {

	return list
		.flatMap( Object.entries )
		.reduce( ( uniqueSet, [ key, value ] ) => {

			if ( !( key in uniqueSet ) ) {
				uniqueSet[ key ] = new Set()
			}

			uniqueSet[ key ].add( value )
			return uniqueSet
		}, {} )
}

const FilterOptions = ( {
	className,
	title,
	options,
	filterColumn,
	filter,
	setFilter
} ) => {

	const [more, setMore] = useState( false )
	const updateFilter = ( event, key, value ) => {

		if ( !( key in filter ) ) {
			filter[ key ] = new Set( [] )
		}

		if ( !( filter[ key ].has( value ) ) ) {
			filter[ key ].add( value )
		} else {
			filter[ key ].delete( value )
		}

		setFilter( Object.assign( {}, filter ) )
	}

	const isFiltered = ( key, value ) => ( ( key in filter && filter[ key ].includes( value ) ) )
	const maxOptions = 5

	return ( <div>
		<div className={className}>{title}</div>
		{
			options
				.slice(
					0,
					more
						? options.length
						: Math.min( maxOptions, options.length )
				)
				.map( ( value, idx ) => ( <div className="mt-1" key={idx}>

					<input
						type="checkbox"
						value="hyper"
						className="mr-3"
						onChange={( event ) => updateFilter( event, filterColumn, value )}
						defaultChecked={() => isFiltered( filterColumn, value )}/>
					<span className="text-capitalize">{value}</span>
				</div> ) )
		}
		{
			options.length > maxOptions && ( <div>
				<div className="text-left pl-4 ml-1">
					<Link
						className="text-info text-spaced py-2"
						onClick={() => setMore( !more )}
						hide="hide"
						to="#">
						{
							more
								? "[ Collapse ]"
								: `[ + ${ options.length - 5 } More ]`
						}
					</Link>
				</div>
			</div> )
		}
	</div> )
}

const SideMenu = ( {
	year: yearParam,
	make: makeParam,
	model: modelParam,
	subModel: subModelParam,
	updateApplications,
	updateApplicationsByPartNumber,
	filter,
	setFilter
} ) => {

	//State
	const [make, setMake] = useState( makeParam )
	const [model, setModel] = useState( modelParam )
	const [subModel, setSubModel] = useState( subModelParam )
	const [year, setYear] = useState( yearParam )
	const [application, setApplication] = useState()
	const [expanded, setExpanded] = useState( false )
	const history = useHistory()

	//Hooks
	//TODO pre-load this if the data exists!
	const [makes, loadingMakes, errorMakes] = useMakes()
	const [getModels, models, loadingModels, errorModels] = useModels( make )
	const [getSubModels, subModels, loadingSubModels, errorSubModels] = useSubModels( make, model )
	const [getYears, years, loadingYears, errorYears] = useYears( make, model, subModel )
	const [productCategoriesList, loadingProductCategories, errorProductCategories] = useProductCategories()
	const [applications, loadingApplications, errorApplications] = useApplicationHistory()
	const { manufacturer: manufacturerSet, productGroup: productGroupSet, productType: productTypeSet } = getUniqueValues( productCategoriesList )

	//Refs
	const sideMenuRef = React.createRef()
	const manufacturers = Array.from( manufacturerSet || [] )
	const productGroups = Array.from( productGroupSet || [] )
	const productTypes = Array
		.from( productTypeSet || [] )
		.map( type => type.replaceAll( "_", " " ) )
		.sort()

	const toggleExpand = () => {
		setExpanded( currentExpanded => !currentExpanded )
	}

	useEffect( () => {
		if ( subModels.length == 1 ) {
			setSubModel( subModels[ 0 ] )
			getYears( make, model, subModels[ 0 ] )
		}
	}, [ subModels ] )

	useEffect( () => {
		if ( years.length == 1 ) {
			setYear( years[ 0 ] )
		}
	}, [ years ] )

	return ( <Card className="justify-content-start text-left rounded-0" ref={sideMenuRef}>
		<ListGroup variant="flush">
			<ListGroup.Item className="bg-light">
				<div className="title text-secondary">
					Search By Part Number
				</div>
				<div align="left" className="mt-1">
					<CustomTextboxButton
						title="Part Number"
						onClick={( partNumber ) => {
							setYear( undefined )
							setMake( undefined )
							setModel( undefined )
							setSubModel( undefined )
							updateApplications( {
								partNumber,
								fuzzy: true
							}, history )
						}}>
						<FontAwesomeIcon icon={faSearch} color="#fff"/>
					</CustomTextboxButton>
				</div>
			</ListGroup.Item>
			<span className={`collapseable-sm ${ expanded
					? "expanded"
					: "" }`}>
				<span className="collapseable-section">
					<ListGroup.Item>
						<div className="title text-secondary">Find Products By Vehicle</div>
						<div align="left" className="mt-2">
							<CustomDropdown
								title="Make"
								showTitle={true}
								value={make}
								items={makes}
								loading={loadingMakes}
								error={errorMakes}
								onSelect={( make ) => {
									setModel( undefined )
									setSubModel( undefined )
									setYear( undefined )
									setMake( make )
									getModels( make )
								}}
								emptyMessage="No Results Matched Your Filter"
								className="vehicle-selector"/>
						</div>
						<div align="left" className="mt-2">
							<CustomDropdown
								title="Model"
								showTitle={true}
								value={model}
								items={models}
								loading={loadingModels}
								error={errorModels}
								onSelect={( model ) => {
									setYear( undefined )
									setSubModel( undefined )
									setModel( model )
									getSubModels( make, model )
								}}
								emptyMessage="Please Select Your Vehicle's Make"
								className="vehicle-selector"/>
						</div>
						<div align="left" className="mt-2">
							<CustomDropdown
								title="Sub Model"
								showTitle={true}
								value={subModel}
								items={subModels}
								loading={loadingSubModels}
								error={errorSubModels}
								onSelect={( subModel ) => {
									setYear( undefined )
									setSubModel( subModel )
									getYears( make, model, subModel )
								}}
								emptyMessage="Please Select Your Vehicles's Model"
								className="vehicle-selector"/>
						</div>
						<div align="left" className="mt-2">
							<CustomDropdown
								title="Year"
								showTitle={true}
								value={year}
								items={years}
								loading={loadingYears}
								error={errorYears}
								onSelect={( year ) => {
									setYear( year )
									setFilter( {} )
								}}
								emptyMessage="Please Select Your Vehicles's Model"
								className="vehicle-selector"/>
						</div>
						<div className="mt-3 mb-1">
							<Button
								variant="primary"
								size="sm"
								disabled={!( make && model && subModel && year )}
								block="block"
								onClick={() => {
									updateApplications( {
										make,
										model,
										subModel,
										year: parseInt( year )
									}, history )
								}}>
								<span>Browse</span>
								<FontAwesomeIcon icon={faSearch} color="#fff" className="ml-2"/>
							</Button>
						</div>
					</ListGroup.Item>
					<ListGroup.Item>
						<div className="title text-secondary">Recent Vehicles</div>
						<div align="left" className="mt-2">
							<CustomDropdown
								title="Select Vehicle"
								showTitle={false}
								value={application}
								items={applications.slice( 0, Math.min( applications.length, 10 ) )}
								itemDisplay={( application ) => {
									if ( application ) {
										let { make, model, subModel, year } = application
										return `${ make} ${ model} ${ subModel} ${ year }`
									} else {
										return undefined
									}
								}}
								loading={loadingApplications}
								error={errorApplications}
								onSelect={( application ) => updateApplications( application, history )}
								emptyMessage="No Saved Applications"
								className="vehicle-selector"/>
						</div>
					</ListGroup.Item>
					<ListGroup.Item>
						<div className="title text-secondary">Application Filters</div>
						<FilterOptions
							title="Manufacturer"
							options={manufacturers}
							filter={filter}
							filterColumn="manufacturer"
							setFilter={setFilter}
							className="subtitle mt-1"/>
						<FilterOptions
							title="Categories"
							options={productGroups}
							filter={filter}
							filterColumn="productGroup"
							setFilter={setFilter}
							className="subtitle mt-2"/>
						<FilterOptions
							title="Product Type"
							options={productTypes}
							filter={filter}
							filterColumn="productType"
							setFilter={setFilter}
							className="subtitle mt-2"/>
					</ListGroup.Item>
				</span>
				<div className="d-lg-none text-center collapseable-toggle">
					<Link to="#" onClick={toggleExpand}>
						<div className="subtitle text-info m-0 p-0">Advanced Search</div>
						<div className="m-0 p-0">
							<FontAwesomeIcon
								icon={faCaretDown}
								className="text-info collapseable-toggle-icon"/>
						</div>
					</Link>
				</div>
			</span>
		</ListGroup>
	</Card> )
}

export default SideMenu

import React, { useState, useEffect, useRef } from 'react'
import { useMutation, useQuery, useLazyQuery } from "@apollo/react-hooks"
import { CardElement } from '@stripe/react-stripe-js';
import gql from "graphql-tag"

const orderSummaryFragment = gql `
	fragment orderSummaryFields on OrderSummary {
    orderId
    orderNumber
    status
    serviceCode
    serviceName
    subTotal
    shippingRate
    shippingServiceDiscount
    totalDiscount
    taxes
    totalCost
		tracking
		shipnumbe
    createdAt
    userInfo{
      firstName
      lastName
      email
      address
      address2
      city
      state
      zipcode
      phoneNumber
    }
    items{
      partNumber
			product{
				defaultImage
				productTitle
				productType
			}
      productType
      retailPrice
      quantity
    }
	}
`

const PROCESS_ORDERS = gql `
  mutation createOrder(
    $items:[OrderItemInput!]!,$userInfo:UserInfoInput!, $costOptionInput:CostOptionInput!){
    createOrder(items:$items, userInfo:$userInfo, costOptionInput:$costOptionInput){
      clientSecret
      nextFulfillmentDay
      orderId
      orderTotal
    }
  }
`

const COST_OPTIONS = gql `
  query costOptions(
    $items:[OrderItemInput!]!,$userInfo:UserInfoInput!, $promoCode:String){
    costOptions(items:$items, userInfo:$userInfo, promoCode:$promoCode){
      serviceCode
      serviceName
      messages
      guaranteedDaysToDelivery
      subTotal
      shippingRate
      shippingServiceDiscount
      totalDiscount
      taxes
      totalCost
    }
  }
`

const ORDER_SUMMARY = gql `
  query orderSummary($orderId:String!){
    orderSummary(orderId:$orderId){
      ...orderSummaryFields
    }
  }

  ${ orderSummaryFragment }
`

const CANCEL_ORDER = gql `
  mutation cancelOrder($orderId:String!){
    cancelOrder(orderId:$orderId, reason: "User Requested"){
      ...orderSummaryFields
    }
  }

  ${ orderSummaryFragment }
`

const SEND_CUSTOMER_MESSAGE = gql `
	mutation sendCustomerMessage($customerMessage:CustomerMessage!){
		sendCustomerMessage(customerMessage:$customerMessage)
	}
`

const getOrderItem = item => ( { partNumber: item.product.partNumber, qty: item.qty } )

const useProcessOrders = ( stripe, elements, cart, userInfo, billingInfo, costOption, promoCode ) => {

	const [paymentStatus, setPaymentStatus] = useState( undefined )
	const [paymentError, setPaymentError] = useState( undefined )
	const [processingPayment, setProcessingPayment] = useState( false )
	const [processPayment, {
			loading
		}
	] = useMutation( PROCESS_ORDERS, {
		onError: err => setProcessingPayment( false ) && setPaymentError( err ),
		onCompleted: async ( response ) => {

			const paymentData = ( response.createOrder )
			console.log( "Processing Payment: ", paymentData )
			var result = await stripe.confirmCardPayment( paymentData.clientSecret, {
				receipt_email: billingInfo.email || userInfo.email,
				shipping: {
					name: `${ userInfo.firstName} ${ userInfo.lastName }`,
					carrier: "UPS",
					phone: userInfo.phoneNumber,
					address: {
						city: userInfo.city,
						state: userInfo.state,
						country: "US",
						line1: userInfo.address,
						line2: userInfo.address2,
						postal_code: userInfo.zipcode
					}
				},
				payment_method: {
					card: elements.getElement( CardElement ),
					billing_details: {
						name: billingInfo.name,
						email: billingInfo.email,
						address: {
							city: "US",
							line1: billingInfo.address,
							line2: billingInfo.address2,
							postal_code: billingInfo.zipcode,
							state: billingInfo.state
						}
					}
				}
			} )

			console.log( "Payment Result: ", result )
			if ( result.error ) {
				setPaymentError( result.error.message )
			} else if ( result.paymentIntent && result.paymentIntent.status == "succeeded" ) {
				setPaymentError( undefined )
				setPaymentStatus( { stripeResponse: result, paymentData, captured: false } )
			} else if ( result.paymentIntent && result.paymentIntent.status == "requires_capture" ) {
				setPaymentError( undefined )
				setPaymentStatus( { stripeResponse: result, paymentData, captured: true } )
			} else {
				setPaymentError( "Oops Something Went Wrong" )
			}
			setProcessingPayment( false )
		}
	} )

	const processPaymentWrapper = async () => {
		setProcessingPayment( true )
		processPayment( {
			variables: {
				items: cart
					.items
					.map( getOrderItem ),
				userInfo,
				costOptionInput: {
					serviceCode: costOption.serviceCode,
					subTotal: costOption.subTotal,
					shippingRate: costOption.shippingRate,
					shippingServiceDiscount: costOption.shippingServiceDiscount,
					totalDiscount: costOption.totalDiscount,
					taxes: costOption.taxes,
					totalCost: costOption.totalCost
				},
				promoCode: promoCode
			}
		} );

		console.log( "LOADING: ", loading )
	}

	return [
		processPaymentWrapper, paymentStatus, loading || processingPayment,
		paymentError
	]
}

const useGetCostOptions = ( cart, userInfo ) => {

	const [getCostOptions, {
			data,
			loading,
			error
		}
	] = useLazyQuery( COST_OPTIONS )

	const getCostOptionsWrapper = ( cart, userInfo, promoCode ) => {

		if ( cart, userInfo ) {

			getCostOptions( {
				variables: {
					items: cart
						.items
						.map( getOrderItem ),
					userInfo,
					promoCode
				}
			} )
		}
	}

	return [
		getCostOptionsWrapper, data && data.costOptions
			? data
				.costOptions
				.sort( ( a, b ) => parseInt( b.serviceCode ) - parseInt( ( a.serviceCode ) ) )
			: [],
		loading,
		error
			? error && error.graphQLErrors
			: undefined
	]
}

const useGetOrderSummary = ( orderId ) => {

	const { data, loading, error } = useQuery( ORDER_SUMMARY, {
		variables: {
			orderId
		},
		skip: !orderId
	} )

	return [
		data && data.orderSummary
			? data.orderSummary
			: {
				userInfo: {},
				items: []
			},
		loading,
		error
	]
}

const useOrder = ( orderId ) => {

	const defaultOrder = {
		userInfo: {},
		items: []
	}
	const [order, setOrder] = useState( defaultOrder )
	const [error, setError] = useState()

	const handleError = ( errorRaw ) => {
		if ( errorRaw && errorRaw.graphQLErrors ) {
			var errorMessageFormatted = errorRaw
				.graphQLErrors
				.map( graphQLError => {
					if ( graphQLError.extensions && graphQLError.extensions && graphQLError.extensions.response ) {
						var body = graphQLError.extensions.response.body
					} else {
						var body = graphQLError.message
					}

					return body
				} )
				.reduce( ( accum, item ) => accum + "\n" + item, "" )

		} else {
			var errorMessageFormatted = errorRaw
		}

		setError( { body: errorMessageFormatted, timestamp: new Date() } )
	}

	const [cancelOrder, {
			loading : cancelLoading,
			called: cancelCalled
		}
	] = useMutation( CANCEL_ORDER, {
		onCompleted: data => data.orderSummary && setOrder( data.orderSummary ),
		onError: ( error ) => handleError( error )
	} );

	const { summaryLoading } = useQuery( ORDER_SUMMARY, {
		skip: cancelCalled || !orderId,
		fetchPolicy: "network-only",
		variables: {
			orderId: orderId
		},
		onCompleted: data => data.orderSummary && setOrder( data.orderSummary ),
		onError: ( error ) => handleError( error )
	} )

	const cancelOrderWrapper = ( orderId ) => {
		cancelOrder( {
			variables: {
				orderId: orderId
			}
		} )
	}

	return {
		orderSummary: order,
		error,
		loading: summaryLoading || cancelLoading,
		cancelOrder: cancelOrderWrapper
	}
}

const useSendCustomerMessage = () => {

	const [result, setResult] = useState()
	const [error, setError] = useState()
	const [sendCustomerMessage, {
			loading
		}
	] = useMutation( SEND_CUSTOMER_MESSAGE, {
		onCompleted: ( data ) => {
			setError( undefined )
			setResult( "success" )
		},
		onError: ( error ) => {
			if ( error && error.networkError ) {
				setError( "missing required field" )
			} else {
				setError( "something went wrong" )
			}
			setResult( undefined )
		}
	} );

	const sendCustomerMessageWrapper = ( customerMessage ) => {
		sendCustomerMessage( { variables: {
				customerMessage
			} } )
	}

	return { sendCustomerMessage: sendCustomerMessageWrapper, result, error, loading }
}

export {
	useProcessOrders,
	useGetCostOptions,
	useGetOrderSummary,
	useSendCustomerMessage,
	useOrder
}

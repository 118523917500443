import React from 'react';
import { Container, Row, Col } from 'react-bootstrap'
import { Link } from 'react-router-dom'

//Static
import './footer.scss'

class Footer extends React.Component {

	render() {

		return ( <Container fluid="fluid" className="footer px-0">
			<div className="d-flex w-100 justify-content-center">
				<div className="d-flex content p-5 justify-content-start text-white bg-dark">
					<Row className="w-100">
						<Col sm="12" lg="4" className="text-left">
							<div className="font-weight-bold text-primary">Help</div>
							<ul className="list-inline mt-2 font-weight-light">
								<li className="mt-1">
									<Link to="/warranty-policy" className="text-white">
										Warranty Policy
									</Link>
								</li>
								{/*<li className="mt-1">Contact</li>*/}
								<li className="mt-1">
									<Link to="/claims-and-warranty-procedures" className="text-white">
										Claims and Warranty Procedures
									</Link>
								</li>
								<li className="mt-1">
									<a
										href={`${ ( process.env.PUBLIC_URL ) }/legal/terms-of-use.pdf`}
										target="_blank"
										className="text-white">
										Terms of Use
									</a>
								</li>
								<li className="mt-1">
									<Link to="/faq" className="text-white">
										Contact Us
									</Link>
								</li>
								<li className="mt-1">
									<Link to="/faq" className="text-white">
										FAQ
									</Link>
								</li>
								<li className="mt-3">
									<b>Tech Support:
										<span className="ml-2">(800) 346-6091</span>
									</b>
								</li>
							</ul>
						</Col>
						<Col sm="12" lg="4" className="text-left">
							<div className="font-weight-bold text-primary">EXEDY Worldwide</div>
							<ul className="list-inline mt-2 font-weight-light">
								<li className="mt-1">
									<a href="https://exedy.com.au/sports-tuff" className="text-white">EXEDY Australia</a>
								</li>
								<li className="mt-1">
									<a href="http://egc.exedy.com/" className="text-white">EXEDY China</a>
								</li>
								<li className="mt-1">
									<a href="http://www.exedy.com/" className="text-white">EXEDY Japan</a>
								</li>
								<li className="mt-1">
									<a href="http://exedylatinamerica.com/" className="text-white">EXEDY Latin America</a>
								</li>
								<li className="mt-1">
									<a href="http://www.exedy.com.my/" className="text-white">EXEDY Malaysia</a>
								</li>
								<li className="mt-1">
									<a href="http://www.exedy.ae/" className="text-white">EXEDY Middle East</a>
								</li>
								<li className="mt-1">
									<a href="http://www.exedy.co.th/" className="text-white">EXEDY Thailand</a>
								</li>
								<li className="mt-1">
									<a href="http://www.exedy.co.uk/" className="text-white">EXEDY UK</a>
								</li>
								<li className="mt-1">
									<a href="http://www.exedyusa.com/" className="text-white">EXEDY Globalparts</a>
								</li>
							</ul>
						</Col>
						<Col sm="12" lg="4" className="text-left">
							<div className="font-weight-bold text-primary">About Us</div>
							<ul className="list-inline mt-2 font-weight-light">
								<li className="mt-1">
									<Link to="/corporate-profile" className="text-white">
										Corporate Profile
									</Link>
								</li>
								<li className="mt-1">
									<a href="http://influencer.exedyusa.com/" className="text-white">
										Influencer Program
									</a>
								</li>
								<li className="mt-1">
									<a href="http://paid2podium.com/" className="text-white">
										Contigency Program
									</a>
								</li>
							</ul>
						</Col>

					</Row>
				</div>
			</div>
		</Container> );
	}
}

export default Footer;

import React, { useState, useEffect } from 'react';
import {
	Button,
	Col,
	ButtonGroup,
	Form,
	FormControl,
	InputGroup
} from 'react-bootstrap'
import { Link, useLocation } from "react-router-dom"
import { CustomDropdown, CustomTextbox, CustomPhoneNumberInput } from "../widgets/custom_widgets"

//Stripe
import { CardElement } from '@stripe/react-stripe-js';
import States from "../utils/states"

const AddressFormFields = ( {
	addressInfo,
	updateAddressCallback,
	prefix = undefined
} ) => {

	const stateList = Object.keys( States )
	let streetAddressLabel = (
		`${ prefix
			? prefix + " "
			: "" } Street Address`
	)

	let streetAddress2Label = (
		`${ prefix
			? prefix + " "
			: "" } Street Address 2`
	)

	return ( <div>
		<Form.Row>
			<Form.Group controlId="formGridAddress1" className="col-12 col-lg-8">
				<Form.Label className="font-small">{streetAddressLabel}</Form.Label>
				<CustomTextbox
					title="Address e.g 1245 Main St"
					onChange={updateAddressCallback.bind( undefined, "address" )}/>
			</Form.Group>

			<Form.Group as={Col} controlId="formGridAddress2">
				<Form.Label className="font-small">{streetAddress2Label}</Form.Label>
				<CustomTextbox
					title="Apartment, studio, or floor"
					onChange={updateAddressCallback.bind( undefined, "address2" )}/>
			</Form.Group>
		</Form.Row>

		<Form.Row>
			<Form.Group as={Col} controlId="formGridCity" className="col-12 col-lg-4">
				<Form.Label className="font-small">City</Form.Label>
				<CustomTextbox
					title="City"
					onChange={updateAddressCallback.bind( undefined, "city" )}/>
			</Form.Group>

			<Form.Group as={Col} controlId="formGridState" className="col-12 col-lg-4">
				<Form.Label className="font-small">State</Form.Label>
				<CustomDropdown
					title="State"
					value={addressInfo.state}
					items={stateList}
					onSelect={updateAddressCallback.bind( undefined, "state" )}
					emptyMessage="Please Select Your State"
					className="vehicle-selector"/>
			</Form.Group>

			<Form.Group as={Col} controlId="formGridZip" className="col-12 col-lg-4">
				<Form.Label className="font-small">Zipcode</Form.Label>
				<CustomTextbox
					title="Zipcode"
					onChange={updateAddressCallback.bind( undefined, "zipcode" )}/>
			</Form.Group>
		</Form.Row>
	</div> )
}

const BillingForm = ( { billingInfo, setBillingInfo } ) => {

	const updateBillingInfo = ( field, value ) => setBillingInfo( ( previous ) => {
		let newState = Object.assign( {}, previous )
		newState[ field ] = value
		return newState
	} )

	return ( <Form>
		<Form.Group controlId="billingDetailsCC">
			<Form.Label className="font-small">
				Credit Card
			</Form.Label>
			<div className="input-group input-group-sm">
				<CardElement className="border border-secondary form-control"/>
			</div>
		</Form.Group>
		<Form.Row>
			<Form.Group as={Col} controlId="billingDetailsName" className="col-12 col-lg-6">
				<Form.Label className="font-small">Name As It Appears On Card</Form.Label>
				<CustomTextbox
					title="Name"
					onChange={updateBillingInfo.bind( undefined, "name" )}/>
			</Form.Group>
			<Form.Group
				as={Col}
				controlId="billingDetailsEmail"
				className="col-12 col-lg-6">
				<Form.Label className="font-small">Email</Form.Label>
				<CustomTextbox
					title="Email"
					onChange={updateBillingInfo.bind( undefined, "email" )}/>
			</Form.Group>
		</Form.Row>

		<AddressFormFields
			prefix="Billing"
			addressInfo={billingInfo}
			updateAddressCallback={updateBillingInfo}/>
	</Form> )
}

const ShippingForm = ( { shippingInfo, setShippingInfo } ) => {

	const updateShippingInfo = ( field, value ) => setShippingInfo( ( previous ) => {
		let newState = Object.assign( {}, previous )
		newState[ field ] = value
		return newState
	} )

	return ( <Form>
		<Form.Row>
			<Form.Group as={Col} controlId="formGridFirstName" className="col-12 col-lg-6">
				<Form.Label className="font-small">First Name</Form.Label>
				<CustomTextbox
					title="First Name"
					onChange={updateShippingInfo.bind( undefined, "firstName" )}/>
			</Form.Group>

			<Form.Group as={Col} controlId="formGridLastName" className="col-12 col-lg-6">
				<Form.Label className="font-small">Last Name</Form.Label>
				<CustomTextbox
					title="Last Name"
					onChange={updateShippingInfo.bind( undefined, "lastName" )}/>
			</Form.Group>
		</Form.Row>

		<Form.Row>
			<Form.Group as={Col} className="col-12 col-lg-8" controlId="formGripEmail">
				<Form.Label className="font-small">Email</Form.Label>
				<CustomTextbox
					title="Email"
					onChange={updateShippingInfo.bind( undefined, "email" )}/>
			</Form.Group>

			<Form.Group as={Col} controlId="formGridPhoneNumber">
				<Form.Label className="font-small">Phone Number</Form.Label>
				<CustomPhoneNumberInput
					title="Phone Number"
					onChange={updateShippingInfo.bind( undefined, "phoneNumber" )}/>
			</Form.Group>
		</Form.Row>

		<AddressFormFields
			addressInfo={shippingInfo}
			updateAddressCallback={updateShippingInfo}/>

		<small>
			<p>
				* At this moment shipments can only be made within the continental United
				States. For addresses outside of the United States please &nbsp;
				<Link to="/faq" className="text-info">Contact Customer Service</Link>
			</p>
		</small>
	</Form> )
}

export {
	BillingForm,
	ShippingForm
}

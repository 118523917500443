import React, { useState, useEffect } from 'react';
import { useMakeModel, useMakes, useModels, useSubModels, useYears } from '../../hooks/vehicles'
import { Button, Row, Col, Form } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { CustomDropdown, CustomTextbox } from "./custom_widgets"

export default( {
	make: defaultMake,
	model: defaultModel,
	subModel: defaultSubModel,
	year: defaultYear,
	addApplication,
	actionTitle,
	faIcon,
	size,
	variant
} ) => {

	const [make, setMake] = useState( defaultMake )
	const [model, setModel] = useState( defaultModel )
	const [subModel, setSubModel] = useState( defaultSubModel )
	const [year, setYear] = useState( defaultYear )
	const [vehicle, setVehicle] = useState()

	//Hooks
	const [makes, loadingMakes, errorMakes] = useMakes()
	const [getModels, models, loadingModels, errorModels] = useModels( make )
	const [getSubModels, subModels, loadingSubModels, errorSubModels] = useSubModels( make, model )
	const [getYears, years, loadingYears, errorYears] = useYears( make, model, subModel )

	//TODO Only show matches
	const formatVehicle = ( vehicle ) => {
		if ( vehicle ) {
			let { make, model, subModel, year } = vehicle
			return `${ make} ${ model} ${ subModel} ${ year }`
		} else {
			return undefined
		}
	}

	useEffect( () => {
		setModel( defaultModel )
	}, [ defaultModel ] )

	useEffect( () => {
		setSubModel( defaultSubModel )
	}, [ defaultSubModel ] )

	useEffect( () => {
		if ( subModels.length == 1 ) {
			setSubModel( subModels[ 0 ] )
			getYears( make, model, subModels[ 0 ] )
		}
	}, [ subModels ] )

	useEffect( () => {
		if ( years.length == 1 ) {
			setYear( years[ 0 ] )
		}
	}, [ years ] )

	return ( <Form inline="inline" className="w-100">
		<Row className="mx-0 w-100">
			<Col xs="12" lg={{}} className="px-0 mt-2 mt-lg-0">
				<CustomDropdown
					title="Make"
					value={make}
					items={makes}
					loading={loadingMakes}
					error={errorMakes}
					onSelect={( make ) => {
						setModel( undefined )
						setSubModel( undefined )
						setYear( undefined )
						setMake( make )
						getModels( make )
					}}
					size={size}
					variant={variant}
					className="vehicle-selector"
					emptyMessage="No Results Matched Your Filter"/>
			</Col>
			<Col xs="12" lg={{}} className="px-0 pl-lg-2 mt-2 mt-lg-0">
				<CustomDropdown
					title="Model"
					value={model}
					items={models}
					loading={loadingModels}
					error={errorModels}
					onSelect={( model ) => {
						setYear( undefined )
						setSubModel( undefined )
						setModel( model )
						getSubModels( make, model )
					}}
					size={size}
					variant={variant}
					emptyMessage="Please Select Your Vehicle's Make"
					className="vehicle-selector"/>
			</Col>
			<Col xs="12" lg={{}} className="px-0 pl-lg-2 mt-2 mt-lg-0">
				<CustomDropdown
					title="Sub Model"
					value={subModel}
					items={subModels}
					loading={loadingSubModels}
					error={errorSubModels}
					onSelect={( subModel ) => {
						setYear( undefined )
						setSubModel( subModel )
						getYears( make, model, subModel )
					}}
					size={size}
					variant={variant}
					emptyMessage="Please Select Your Vehicles's Model"
					className="vehicle-selector"/>
			</Col>
			<Col xs="12" lg={{}} className="px-0 pl-lg-2 mt-2 mt-lg-0">
				<CustomDropdown
					title="Year"
					value={year}
					items={years}
					loading={loadingYears}
					error={errorYears}
					onSelect={( year ) => setYear( year )}
					size={size}
					variant={variant}
					emptyMessage="Please Select Your Vehicles's Sub Model"
					className="vehicle-selector"/>
			</Col>
			<Col xs="12" md="auto" className="px-0 pl-lg-2 mt-2 mt-lg-0">
				<Button
					variant="primary"
					size={size || "sm"}
					block="block"
					className="btn-title rounded-0 pl-3"
					onClick={addApplication.bind( undefined, make, model, subModel, year, false, false )}>
					<span>{actionTitle}</span>
					<FontAwesomeIcon icon={faIcon} className="mx-2"/>
				</Button>
			</Col>
		</Row>
	</Form> )
}

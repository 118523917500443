import React, { useState, useEffect } from 'react';
import { Button, ButtonGroup, Row, Col, Jumbotron } from 'react-bootstrap'
import { LazyLoadImage } from 'react-lazy-load-image-component';
//Font Awesome
import { faVolumeUp, faVolumeMute } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

//Static
import contingencyVideo from "./contingency.mp4"
import "./contingency.scss"

const ContigencySlide = ( { setBrowseDefaults } ) => {

	const videoRef = React.createRef()
	const [muted, setMuted] = useState( true )

	const toggleMute = () => {

		if ( videoRef.current ) {
			videoRef.current.muted = !muted
			setMuted( !muted )
		}
	}

	return ( <div
		className="bg-black d-flex justify-content-center flex-column d-lg-block carousel-img contingency-slide">
		<Row className="m-0 p-0 video-container-row">
			<Col
				xs={12}
				className="d-flex h-100 justify-content-center align-items-center video-container p-0 m-0">
				<video ref={videoRef} autoPlay="autoPlay" muted="muted" loop="loop">
					<source src={contingencyVideo} type="video/mp4"/>
				</video>
				<div className="d-flex align-items-center justify-content-end mute px-3">
					<FontAwesomeIcon
						icon={muted
							? faVolumeUp
							: faVolumeMute}
						className="text-white"
						onClick={toggleMute}/>
				</div>
			</Col>
		</Row>
		<Row className="d-flex mx-0 px-0 py-3 w-100 signup justify-content-center">
			<Col className="d-flex align-items-center justify-content-center p-0">
				<div>
					<div className="d-block d-lg-none text-white text-center px-3">
						<h1 className="font-weight-light text-uppercase">Signup Today</h1>
					</div>
					<div
						className="text-center embed rounded-left-pill rounded-right-pill py-2 px-2">
						<Button
							className="rounded-left-pill rounded-right-pill px-5 signup-button"
							onClick={() => document.location = "https://paid2podium.com/"}>
							Learn More
						</Button>
					</div>
				</div>
			</Col>
		</Row>
	</div> )
}

export default {
	slide: ContigencySlide,
	options: {
		interval: 20000
	}
}

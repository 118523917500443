import React, { useEffect, useState, useContext } from 'react';
import { Button, Card, Col, Form, Row } from 'react-bootstrap'
import { Link, useHistory } from "react-router-dom"
//Components
import { CustomTextbox } from "../widgets/custom_widgets"
//Config
import { AppConfig } from "../../app.js"
//Icons
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronCircleLeft } from '@fortawesome/free-solid-svg-icons'
import { useElements, useStripe } from '@stripe/react-stripe-js';
//Hooks
import { useGetCostOptions, useProcessOrders } from '../../hooks/orders'

const formatMoney = ( value ) => value
	? `$${ value.toFixed( 2 ) }`
	: ""

const CartSummary = ( {
	cart,
	clearCart,
	shippingInfo,
	billingInfo,
	slideIndex,
	previousSlide,
	processPaymentDisabled,
	setPaymentStatusError
} ) => {

	const defaultOption = {
		default: true,
		serviceCode: undefined,
		serviceName: 'UPS Ground',
		messages: [],
		shippingRate: NaN,
		guaranteedDaysToDelivery: undefined,
		subTotal: NaN,
		shippingServiceDiscount: NaN,
		totalDiscount: NaN,
		taxes: NaN,
		totalCost: NaN
	}
	const history = useHistory()
	const appConfig = useContext( AppConfig )
	const [costOption, setCostOption] = useState( defaultOption )
	const [promoCode, setPromoCode] = useState()
	const [getCostOptions, costOptions, loadingCostOptions, errorCostOptions] = useGetCostOptions()
	//Stripe
	const stripe = useStripe()
	const elements = useElements()
	const [processPayment, paymentStatus, loadingPaymentStatus, paymentError] = useProcessOrders( stripe, elements, cart, shippingInfo, billingInfo, costOption, promoCode )

	useEffect( () => {
		if ( costOptions && costOptions.length ) {
			process.nextTick( () => setCostOption( costOptions[ 0 ] ) )
		}
	}, [ costOptions ] )

	useEffect( () => {
		if ( !processPaymentDisabled ) {
			process.nextTick( () => getCostOptions( cart, shippingInfo, promoCode ) )
		}
	}, [ processPaymentDisabled, cart, shippingInfo ] )

	useEffect( () => {
		if ( paymentStatus ) {
			clearCart()
			history.push( `/payment-confirmation?orderId=${ paymentStatus.paymentData.orderId}
				&captured=${ paymentStatus.captured}
				&nextFulfillmentDay=${ paymentStatus.paymentData.nextFulfillmentDay }` )
		}
	}, [ paymentStatus ] )

	const reviewItemWidget = slideIndex > 0
		? ( <div className="text-right">
			<Link to="#" className="text-info" onClick={previousSlide} disabled="disabled">
				<FontAwesomeIcon icon={faChevronCircleLeft} className="mr-2"/>
				<span>Review Items</span>
			</Link>
		</div> )
		: ( <div className="text-right text-danger">
			Reviewing Items
		</div> )

	return ( <Card className="justify-content-start text-left p-4 pb-4 sticky rounded-0">
		<div className="title">
			Order Summary
		</div>
		<div className="mt-2 border-secondary border-bottom">
			<ShoppingCartItems cart={cart}/>
			<div className="my-2">{reviewItemWidget}</div>
		</div>
		<div className="mt-2 pb-2 border-secondary border-bottom">
			<div className="title mt-2">
				Shipping Options
			</div>
			<div className="mt-2">
				<CostOptions
					costOption={costOption}
					options={costOptions}
					loading={loadingCostOptions}
					error={errorCostOptions}
					setCostOption={setCostOption}
					defaultService="03"/>
			</div>
			<div className="mt-2 text-secondary">
				<small>
					All UPS Ground orders, within the continental United States, totaling $100.00
					and above are shipped FREE.
				</small>
			</div>
		</div>
		<div className="mt-2 pb-2 border-secondary border-bottom">
			<div className="title mt-2">
				Promo Code
			</div>
			<div className="mt-2">
				<Row>
					<Col className="pr-0">
						<CustomTextbox
							title="Promo Code"
							onChange={( value ) => setPromoCode( value )}/>
					</Col>
					<Col xs="auto">
						<Button
							variant="primary"
							size="sm"
							block="block"
							disabled={processPaymentDisabled}
							onClick={() => getCostOptions( cart, shippingInfo, promoCode )}>
							Apply
						</Button>
					</Col>
				</Row>
				<div className="text-left mt-2">
					<small>
						<a href={appConfig.influencerLink} className="text-info">
							What is this? Learn more about our Influencer Program.
						</a>
					</small>
				</div>
			</div>
		</div>
		{
			errorCostOptions && ( <div className="my-2">
				{
					errorCostOptions.map( ( error, idx ) => ( <div key={idx} className="text-danger">
						<small>*{error.message}</small>
					</div> ) )
				}
			</div> )
		}
		<div className="mt-2">
			<Row className="mt-1 mx-0 pt-2 font-weight-bold">
				<Col className="px-0">
					Sub Total
				</Col>
				<Col xs="3" className="text-right px-0">
					{formatMoney( costOption.subTotal )}
				</Col>
			</Row>
			<Row className="mt-1 mx-0 pt-2 font-weight-bold">
				<Col className="px-0">
					Shipping Costs
				</Col>
				<Col xs="3" className="text-right px-0">
					{formatMoney( costOption.shippingRate )}
				</Col>
			</Row>
			{
				!!costOption.totalDiscount && ( <Row className="mt-2 mx-0 pt-2 font-weight-bold">
					<Col className="px-0">
						Discount
					</Col>
					<Col xs="3" className="text-right px-0">
						- {formatMoney( costOption.totalDiscount )}
					</Col>
				</Row> )
			}
			<Row className="mt-1 mx-0 pt-2 font-weight-bold">
				<Col className="px-0">
					Taxes
				</Col>
				<Col xs="3" className="text-right px-0">
					{formatMoney( costOption.taxes )}
				</Col>
			</Row>
			<h3>
				<Row className="mt-2 font-weight-bold">
					<Col>
						Total
					</Col>
					<Col xs="auto" className="text-right">
						{formatMoney( costOption.totalCost )}
					</Col>
				</Row>
			</h3>
			{
				paymentError && paymentError.graphQLErrors && paymentError
					.graphQLErrors
					.map( ( error, idx ) => ( ( <div key={idx} className="text-danger">*{error.message}</div> ) ) )
			}
			{paymentError && ( typeof paymentError == "string" ) && ( <div className="text-danger">*{paymentError}</div> )}
			<div className="m-0 mt-4 px-0">
				<Button
					variant="primary"
					block="block"
					onClick={processPayment}
					disabled={( loadingPaymentStatus || processPaymentDisabled || costOption.default )}>
					{
						loadingPaymentStatus
							? 'Processing Payment'
							: 'Place Your Order'
					}
				</Button>
			</div>
			<div className="mt-2 text-center">
				<small className="text-secondary">
					By placing your order you agree to&nbsp;
					<b>Exedy USA's</b>
					<br/>
					<a
						href={`${ ( process.env.PUBLIC_URL ) }/legal/terms-of-use.pdf`}
						className="text-info">
						Terms and Conditions
					</a>
				</small>
			</div>
		</div>
	</Card> )
}

const ShoppingCartItems = ( { cart } ) => {

	if ( cart ) {
		return ( cart.items.map( ( item, cartIdx ) => ( <Row className="px-0 py-1 mx-0" key={cartIdx}>
			<Col className="px-0">
				<Row className="px-0 mx-0">
					<Col className="px-0">
						<div className="font-weight-light text-secondary">{item.partNumber}</div>
						<div>
							<span className="mr-1">({item.qty})</span>
							<span>
								{item.product.title}
								{item.qty > 1 && "(s)"}
							</span>
						</div>
					</Col>
					<Col xs="3" className="text-right px-0">
						{formatMoney( item.total )}
					</Col>
				</Row>
			</Col>
		</Row> ) ) )
	} else {
		return ( <div>Your Shopping Cart Is Empty</div> )
	}
}

const CostOptions = ( { options, loading, error, costOption, setCostOption } ) => {

	if ( options && options.length ) {
		return ( options.map( ( option, idx ) => ( <Row key={idx} className="mt-1">
			<Col xs="auto" className="pr-0 mr-0">
				<input
					type="radio"
					id="cost_option"
					checked={option.serviceCode == costOption.serviceCode}
					onChange={() => setCostOption( option )}/>
			</Col>
			<Col>
				{option.serviceName}
			</Col>
			<Col className="text-right">
				{
					option.shippingServiceDiscount == 1.0
						? ( <div>
							<span className="text-success">FREE</span>
							<span
								className="ml-2"
								style={{
									textDecoration: "line-through"
								}}>${option.shippingRate}</span>
						</div> )
						: ( <div>${option.shippingRate}</div> )
				}
			</Col>
		</Row> ) ) )
	} else {
		return ( <Row className="text-secondary">
			<Col xs="auto" className="pr-0 mr-0">
				<Form.Check type="radio" disabled="disabled"/>
			</Col>
			<Col>
				UPS Ground
			</Col>
			<Col className="text-right"></Col>
		</Row> )
	}
}

export default CartSummary

import React from 'react';
import {
  Alert,
  Card,
  Container,
  Row,
  Col
} from 'react-bootstrap'

const WarrantyPolicy = ()=>{

  return (
    <Container fluid="fluid">
      <Row className="justify-content-center">
        <Col md="9" sm="12">
          <div className="d-flex justify-content-center my-0 py-5 px-3 px-lg-5">
            <div className="bg-white content p-4 p-lg-5">
              <div className="text-left">
                <h2 className="title text-dark px-0">
                  EXEDY Warranty Policy
                </h2>
                <hr/>
              </div>
              <div className="pl-4">
                <div className="text-left">
                  <p>
                    All goods/products supplied by the Company are warranted against faulty material and/or workmanship.
                    Warranty does not apply if maltreatment, damage caused by collision or incorrect fitting exists.
                    <br/>
                    <br/>
                    Claims will be rejected where clutch kits and/or clutch components have been fitted to incorrect or unlisted applications.
                    Flywheel must be resurfaced/machined to manufacturers specifications before new clutch kit is fitted otherwise warranty will be void.
                  </p>
                </div>
                <div className="text-left mt-4">
                  <h3 className="sub-title text-secondary px-0">
                    Standard OEM Replacement Clutch Kits Standard OEM
                  </h3>
                </div>
                <div className="text-left">
                  <p>
                    Standard OEM replacement clutch kits are guaranteed for a period of  twelve (12) months or 20,000 miles, which ever occurs first.
                  </p>
                </div>

                <div className="text-left mt-4">
                  <h3 className="sub-title text-secondary px-0">
                    Automatic Transmission Kits
                  </h3>
                </div>
                <div className="text-left">
                  <p>
                    Automatic Transmission steels and wet friction products are guaranteed twenty-four (24) months or 50,000 miles,
                    which ever occurs first, against  manufacturers defects.
                    <br/>
                    <br/>
                    Claims on wet friction products will not be  entertained on any claim level,
                    sight unseen of the product that claims to  be faulty.
                  </p>
                </div>

                <div className="text-left mt-4">
                  <h3 className="sub-title text-secondary px-0">
                    Sports/Performance
                  </h3>
                </div>
                <div className="text-left">
                  <p>
                    Due to the intended use and nature of these goods/products, they are  warranted as follows:
                    <ul>
                      <li>Stage 1, Stage 2 and Flywheels - 60 days from date of purchase.</li>
                      <li>Stage 3, Stage 4 and Stage 5 - No Warranty available.</li>
                      <li>Individual components - No Warranty available.</li>
                    </ul>
                  </p>
                </div>

                <div className="text-left mt-4">
                  <h3 className="sub-title text-secondary px-0">
                    OE
                  </h3>
                </div>
                <div className="text-left">
                  <p>
                    OEM Replacement clutch kits and Automatic Transmission kits used in  performance applications are not covered by any warranty.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </Col>
      </Row>
    </Container>)
}

export default WarrantyPolicy
const toDict = (list, key)=>{
  return Object.fromEntries(list.map(item => [item[key], item]))
}

const cherryPick = (list, key, keyValues)=>{

  const dict = toDict(list, key)
  return keyValues.map(value => dict[value]).filter(item => !!item)
}

export {
  toDict,
  cherryPick
}
import React, { createContext, useState, useEffect } from 'react';
import { Alert, Modal } from 'react-bootstrap'
import { useHistory, useLocation } from "react-router-dom";

//Resources
import './app.scss';

import logo from './images/exedy-logo-lg.png';
import annoucementImg from "./images/annoucement.jpg"
import Header from './components/header/header'
import Footer from './components/footer/footer'

const AppConfig = createContext()
const { Provider } = AppConfig

const Announcement = ( {
	logo,
	enabled = true
} ) => {

	const [shouldShow, setShouldShow] = useState( false )

	return ( <Modal
		show={shouldShow && enabled}
		autoFocus={true}
		size="lg"
		centered="centered"
		className="announcement border-2">
		<Modal.Header
			closeButton="closeButton"
			className="border-0 bg-transparent"
			onHide={() => setShouldShow( false )}></Modal.Header>
		<Modal.Body className="bg-transparent px-5 pb-5">
			EXEDY Customers,
			<br/>
			<br/>
			Due to a recent network disruption, we are experiencing some minor delays that
			may impact our ability to process you order promptly. We are working diligently
			to correct this situation as quickly as possible, and appreciate your patience
			on this matter.
			<br/>
			<br/>
			Sincerely, EXEDY
		</Modal.Body>
	</Modal> )
}

const App = ( { setSearchText, cartRef, children } ) => {

	const history = useHistory()
	const location = useLocation()
	//Listen for History changes
	history.listen( location => window.scrollTo( 0, 0 ) )
	//Can initialize app by making cached request to config endpoint.
	const imageHost = "https://images.omnitek-labs.com"
	const influencerLink = "http://influencer.exedyusa.com/"

	return ( <div className="App">
		<Announcement logo={logo} enabled={true}/>
		<Header setSearchText={setSearchText} logo={logo} cartRef={cartRef}/>
		<div className="bg-white">
			<Provider value={{
					imageHost,
					influencerLink
				}}>
				{children}
			</Provider>
		</div>
		<Footer/>
	</div> );
}
export {
	App,
	AppConfig
};

import { useLocation } from "react-router-dom"

export default ( defaultQuery = {} ) => {

	const queryParams = new URLSearchParams( useLocation().search )
	const queryDict = {}

	for ( const [key, value] of queryParams ) {
		try {
			queryDict[ key ] = key === 'year' && value
				? parseInt( value )
				: value
		} catch ( err ) {
			queryDict[ key ] = undefined
		}
	}

	return queryDict
}

import React, { useState, useEffect } from 'react';
import {
	Button,
	ButtonGroup,
	Row,
	Col,
	Carousel,
	Jumbotron
} from 'react-bootstrap'
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { Link } from "react-router-dom"
//Font Awesome
import { faShoppingCart } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

//Static
import "./hyper-series.scss"
import hyperSeriesText from "./hyper-series.png"
import unmatchedPeformanceText from './unmatched-performance.png'
import hyperSeriesClutchImg from "./hyper-series-product.png"

const HyperSeriesSlide = ( { setBrowseDefaults } ) => {

	return ( <div
		className="d-flex align-items-end pb-5 pb-lg-0 carousel-img hyper-series-slide">
		<LazyLoadImage
			visibleByDefault={true}
			src={hyperSeriesClutchImg}
			className="d-none d-lg-block productImage"/>
		<Row
			className="pb-5 mb-4 mb-md-0 justify-content-center"
			style={{
				position: "relative"
			}}>
			<Col
				sm={{
					order: "last",
					span: "12"
				}}
				md={{
					order: "first",
					span: "8"
				}}
				lg={{
					order: "first",
					span: "6"
				}}
				xl={{
					order: "first",
					span: "4"
				}}
				className="my-auto w-100 justify-content-center pb-5 px-5 px-lg-0">
				<div>
					<div className="title text-center mb-n1">
						<LazyLoadImage src={hyperSeriesText} width="100%"/>
					</div>
					<div className="text-center">
						<LazyLoadImage src={unmatchedPeformanceText} width="100%"/>
					</div>
					<div className="subtitle text-center mt-3">
						<Link
							to="#"
							className="text-white"
							onClick={() => setBrowseDefaults( { title: "Shop Hyper Series", productType: "hyper_multi_clutch_kit" } )}>
							BROWSE CLUTCH KITS
						</Link>
						<FontAwesomeIcon icon={faShoppingCart} className="ml-3 icon"/>
					</div>
				</div>
			</Col>
		</Row>
	</div> )
}

export default { slide: HyperSeriesSlide, options: {} }
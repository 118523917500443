import React from 'react';
import ReactDOM from 'react-dom';
import Routes from './routes';
import * as serviceWorker from './serviceWorker';

import { ApolloClient } from "apollo-client";
import { InMemoryCache } from "apollo-cache-inmemory";
import { HttpLink } from "apollo-link-http";
import { ApolloProvider } from "@apollo/react-hooks";
//Style
import './index.scss';

const apiHost = process.env.REACT_APP_HOST
const cache = new InMemoryCache();
const link = new HttpLink( { uri: apiHost, credentials: 'include' } );
const client = new ApolloClient( { cache, link } )

ReactDOM.render( <ApolloProvider client={client}>
	<Routes/>
</ApolloProvider>, document.getElementById( 'root' ) );

serviceWorker.unregister();

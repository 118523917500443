import React, { useEffect, useRef, useState } from 'react';
import { useLazyQuery, useMutation, useQuery } from "@apollo/react-hooks";
import gql from "graphql-tag"

const applicationsFragment = gql `
  fragment applicationsFields on Application {
    make
    model
    subModel
    year
    engineSize
    note
  }
`

const productFragment = gql `
  fragment productFields on Product {
    partNumber
    manufacturer
    productGroup
    productCategory
    productType
    productTitle
    description
    title
    discType
    clutchDiscDiameter
    clutchDiscHubDiamter
    clutchDiscSplineQuantity
    width
    length
    height
    weight
    defaultImage
    productInventory{
      qty
      retailPrice
    }
  }
`

const cartFrament = gql `
	fragment cartFields on Cart {
		size
		items{
			product {
				partNumber
				productCategory
				productType
				productGroup
				productTitle
				description
				title
				defaultImage
        productInventory{
          qty
          retailPrice
        }
			}
			qty
      total
		}
	}
`

const GET_PRODUCT_BY_PART_NUMBER = gql `
  query product($partNumber: String!){
    product(partNumber:$partNumber){
      ...productFields
    }
  }

  ${ productFragment }
`

const GET_PRODUCT_APPLICATIONS = gql `
  query productApplications($make: String!, $model:String!, $subModel: String!, $year: Int!, $productType: String, $save:Boolean=true){
    productApplications(make:$make, model:$model, subModel:$subModel, year:$year, productType:$productType, save:$save){
      product{
        ...productFields
      }
      applications{
        ...applicationsFields
      }
    }
  }
  ${ productFragment}
  ${ applicationsFragment }
`

const GET_PRODUCT_APPLICATIONS_BY_PART_NUMBER = gql `
  query productApplicationByPartNumber($partNumber:String!, $fuzzy:Boolean){
    productApplicationByPartNumber(partNumber:$partNumber, fuzzy:$fuzzy){
      product{
        ...productFields
      }
      applications{
        ...applicationsFields
      }
      relatedProducts{
        compatibilityType
        product{
          ...productFields
        }
      }
    }
  }
  ${ productFragment}
  ${ applicationsFragment }
`

const GET_PRODUCT_APPLICATIONS_DETAILS_BY_PART_NUMBER = gql `
  query productApplicationByPartNumber($partNumber:String!){
    productApplicationByPartNumber(partNumber:$partNumber){
      product{
        ...productFields
        productTitle
        attributes{
       		attributeType
       		attribute
        }
        alternateImages{
        	location
        	version
        }
      }
      applications{
        ...applicationsFields
        note
      }
    }
  }
  ${ productFragment}
  ${ applicationsFragment }
`

const SEARCH_APPLICATIONS = gql `
  query searchProductApplications($searchText: String!){
    searchProductApplications(text:$searchText){
      score
      product{
        ...productFields
      }
      applications{
        ...applicationsFields
      }
    }
  }
  ${ productFragment}
  ${ applicationsFragment }
`

const GET_RELATED_PRODUCTS = gql `
  query relatedProducts($partNumber: String!){
    relatedProducts(partNumber:$partNumber){
      compatibilityType
      product{
        ...productFields
      }
    }
  }
  ${ productFragment }
`

const GET_PRODUCT_CATEGOREIS = gql `
  query productCategories{
    productCategories{
      manufacturer
      productGroup
      productCategory
      productType
      productTitle
      description
      featuredProduct
      featuredProductImagePath
    }
  }
`

const GET_PRODUCT_TYPE_DESCRIPTIONS = gql `
  query productTypeDescriptions{
    productTypeDescriptions{
      productType
      productTypeDescription
    }
  }
`

const GET_CART = gql `
  query cart{
    cart{
      ...cartFields
    }
  }

  ${ cartFrament }
`

const ADD_TO_CART = gql `
  mutation addToCart($partNumber:String!, $qty:Int){
    addToCart(partNumber:$partNumber, qty: $qty){
     ...cartFields
    }
  }

  ${ cartFrament }
`

const REMOVE_FROM_CART = gql `
  mutation removeFromCart($partNumber:String!){
    removeFromCart(partNumber:$partNumber){
      ...cartFields
    }
  }

  ${ cartFrament }
`

const CLEAR_CART = gql `
  mutation clearCart{
    clearCart{
      ...cartFields
    }
  }

  ${ cartFrament }
`

const GET_APPLICATION_HISTORY = gql `
  query applicationHistory{
    applicationHistory{
      make
      model
      subModel
      year
      isDefault
      saved
    }
  }
`

const ADD_APPLICATION_TO_HISTORY = gql `
  mutation addApplicationToHistory($make:String!, $model:String!, $subModel:String!, $year:Int!, $isDefault:Boolean, $save:Boolean=false){
    addApplicationToHistory(make:$make,model:$model, subModel:$subModel, year:$year, isDefault:$isDefault, save:$save){
      make
      model
      subModel
      year
      isDefault
      saved
    }
  }
`

const CHECK_FITMENT = gql `
  query checkFitment($partNumber:String!, $applications:[ApplicationInput!]!){
    checkFitment(partNumber:$partNumber, applications:$applications){
      fits
      partNumber
      make
      model
      subModel
      year
      engineSize
    }
  }
`

const GET_INVENTORY_LEVELS = gql `
  query inventoryLevels($partNumbers:[String!]!){
    inventoryLevels(partNumbers:$partNumbers){
      partNumber
      qty
    }
  }
`

const useProduct = ( partNumber ) => {

	const { data, loading, error } = useQuery( GET_PRODUCT_BY_PART_NUMBER, { variables: {
			partNumber
		} } )

	return [
		data
			? data.product
			: undefined,
		loading,
		error
	]
}

const useProductApplications = ( {
	make,
	model,
	subModel,
	year,
	productType,
	partNumber,
	search: searchText,
	fuzzy
} ) => {

	//State
	const [productApplications, setProductApplications] = useState( [] )
	const [error, setError] = useState()

	//Hooks
	const [getProductApplications, productApplicationsResult] = useLazyQuery( GET_PRODUCT_APPLICATIONS, {
		onCompleted: ( data ) => data && setProductApplications( data.productApplications ),
		onError: ( error ) => console.log( "ERROR: ", error )
	} )

	const [getProductApplicationsByPartNumber, productApplicationsByPartNumberResult] = useLazyQuery( GET_PRODUCT_APPLICATIONS_BY_PART_NUMBER, {
		onCompleted: ( data ) => {
			console.log( "COMPLETED GET PRODUCT BY PART NUMBER: ", data )
			if ( data && data.productApplicationByPartNumber ) {
				setProductApplications( data.productApplicationByPartNumber )
			} else {
				setProductApplications( [] )
			}
		},
		onError: setError
	} )

	const [searchProductApplications, searchProductApplicationsResult] = useLazyQuery( SEARCH_APPLICATIONS, {
		onCompleted: ( data ) => setProductApplications( data.searchProductApplications ),
		onError: setError
	} )

	//Helpers
	const getProductApplicationsWrapper = ( make, model, subModel, year, productType ) => {
		if ( make && model && subModel && year ) {
			getProductApplications( {
				variables: {
					make,
					model,
					subModel,
					year,
					productType
				}
			} )
		}
	}

	const getProductApplicationsByPartNumberWrapper = ( partNumber, fuzzy ) => {
		if ( partNumber ) {
			getProductApplicationsByPartNumber( {
				variables: {
					partNumber,
					fuzzy: !!fuzzy
				}
			} )
		}
	}

	const searchApplicationsWrapper = ( searchText ) => {
		if ( searchText ) {
			searchProductApplications( { variables: {
					searchText
				} } )
		}
	}

	useEffect( () => {
		getProductApplicationsWrapper( make, model, subModel, year, productType )
	}, [ make, model, subModel, year ] )

	useEffect( () => {
		searchApplicationsWrapper( searchText )
	}, [ searchText ] )

	useEffect( () => {
		getProductApplicationsByPartNumberWrapper( partNumber, fuzzy )
	}, [ partNumber ] )

	return [
		getProductApplicationsWrapper, getProductApplicationsByPartNumberWrapper, productApplications, productApplicationsResult.loading || searchProductApplicationsResult.loading,
		error
	]
}

const useProductApplicationsDetails = ( partNumber ) => {

	const defaultResult = {
		product: {
			productInventory: {}
		},
		applications: []
	}

	const { data, loading, error } = useQuery( GET_PRODUCT_APPLICATIONS_DETAILS_BY_PART_NUMBER, {
		// fetchPolicy: 'cache-first', nextFetchPolicy: 'cache-and-network',
		skip: !partNumber,
		pollInterval: 500,
		variables: {
			partNumber
		}
	} )

	if ( data ) {
		if ( data.productApplicationByPartNumber ) {
			console.log( "DATA", data.productApplicationByPartNumber[0], loading, error )
		}
	}

	//Handler here need to handle case where too many results are returned!
	return [
		data && data.productApplicationByPartNumber && data.productApplicationByPartNumber.length
			? data
				.productApplicationByPartNumber
				.pop()
			: defaultResult,
		loading,
		error
	]
}

const useRelatedProducts = ( partNumber ) => {

	const { data, loading, error } = useQuery( GET_RELATED_PRODUCTS, {
		skip: !partNumber,
		variables: {
			partNumber
		}
	} )

	return [
		data
			? data.relatedProducts
			: [],
		loading,
		error
	]
}

const useProductApplicationsForProduct = ( product ) => {

	const { data, loading, error } = useQuery( GET_PRODUCT_APPLICATIONS_BY_PART_NUMBER, {
		skip: !product,
		variables: {
			partNumber: product
				? product.partNumber
				: undefined
		}
	} )

	return [
		data
			? data.productApplicationByPartNumber.applications
			: undefined,
		data
			? data.productApplicationByPartNumber.relatedProducts
			: [],
		loading,
		error
	]
}

const useProductCategories = () => {

	const { data, loading, error } = useQuery( GET_PRODUCT_CATEGOREIS )

	return [
		data
			? data.productCategories
			: [],
		loading,
		error
	]
}

const useProductTypeDescriptions = () => {

	const { data, loading, error } = useQuery( GET_PRODUCT_TYPE_DESCRIPTIONS )

	if ( data && data.productTypeDescriptions ) {
		var productTypeDescriptionsMap = Object.fromEntries( data.productTypeDescriptions.map( ( productTypeDescriptions ) => [ productTypeDescriptions.productType, productTypeDescriptions.productTypeDescription ] ) )
	} else {
		var productTypeDescriptionsMap = {}
	}
	return [ productTypeDescriptionsMap, loading, error ]
}

const useInventoryLevels = ( partNumbers ) => {

	const { data, loading, error } = useQuery( GET_INVENTORY_LEVELS, {
		variables: {
			partNumbers
		},
		skip: !partNumbers || !partNumbers.length
	} )

	const getInventoryLevelMap = ( inventoryLevels ) => Object.fromEntries( inventoryLevels.map( level => [ level.partNumber, level.qty ] ) )

	return [
		data
			? getInventoryLevelMap( data.inventoryLevels )
			: {},
		loading,
		error
	]
}

const useGetCart = ( ref ) => {

	const defaultCart = {
		items: [],
		size: 0
	}
	const [cart, setCart] = useState( defaultCart )
	var {
		data,
		loading,
		error
	} = useQuery( GET_CART, {
		fetchPolicy: "network-only",
		onCompleted: data => {
			if ( data && data.cart ) {
				setCart( data.cart )
			}
		}
	} )

	const updateCartListener = ( event ) => {

		if ( event && event.detail ) {
			process.nextTick( () => {
				setCart( ( previousState ) => {
					return {
						...previousState,
						...event.detail
					}
				} )
			} )
		}
	}

	useEffect( () => {
		window.removeEventListener( 'updated_cart', updateCartListener )
		window.addEventListener( 'updated_cart', updateCartListener, { once: true } )
	} )

	return [ cart, loading, error ]
}

const useCart = () => {

	const defaultCart = {
		items: [],
		size: 0
	}
	const [cart, setCart] = useState( defaultCart )
	const [error, setError] = useState( undefined )
	const [addToCart, {
			addLoading,
			addCalled
		}
	] = useMutation( ADD_TO_CART, {
		onCompleted( data ) {

			var event = new CustomEvent( 'updated_cart', {
				bubbles: true,
				detail: data.addToCart
			} )
			window.dispatchEvent( event )
			setCart( data.addToCart )
		},
		onError: ( error ) => setError( error )
	} );

	const [removeFromCart, {
			removeLoading,
			removeCalled
		}
	] = useMutation( REMOVE_FROM_CART, {
		onCompleted( data ) {

			var event = new CustomEvent( 'updated_cart', {
				bubbles: true,
				detail: data.removeFromCart
			} )
			window.dispatchEvent( event )
			setCart( data.removeFromCart )
		},
		onError: ( error ) => setError( error )
	} );

	const [clearCart, {
			clearLoading,
			clearCalled
		}
	] = useMutation( CLEAR_CART, {
		onCompleted( data ) {

			var event = new CustomEvent( 'updated_cart', {
				bubbles: true,
				detail: data.clearCart
			} )
			window.dispatchEvent( event )
			setCart( data.clearCart )
		},
		onError: ( error ) => setError( error )
	} );

	const { data, getLoading, getError } = useQuery( GET_CART, {
		skip: addCalled || removeCalled || clearCalled,
		fetchPolicy: "network-only",
		onCompleted: data => data && data.cart && setCart( data.cart ),
		onError: ( error ) => setError( error )
	} )

	const addToCartWrapper = ( product, qty ) => {
		addToCart( {
			variables: {
				partNumber: product.partNumber,
				qty
			}
		} )
	}

	const removeFromCartWrapper = ( product ) => {

		removeFromCart( {
			variables: {
				partNumber: product.partNumber
			}
		} )
	}

	const updateCartListener = ( event ) => {

		if ( event && event.detail ) {
			process.nextTick( () => {
				setCart( ( previousState ) => {
					return {
						...previousState,
						...event.detail
					}
				} )
			} )
		}
	}

	useEffect( () => {
		//Multiple Events could make this behavior weird
		//TODO Look into how this would work for multiple events
		window.addEventListener( 'updated_cart', updateCartListener, { once: true } )
		return() => window.removeEventListener( 'updated_cart', updateCartListener )
	} )

	if ( error && error.graphQLErrors ) {
		var errorMessages = error
			.graphQLErrors
			.reduce( ( errorObj, errorItem ) => {
				let { partNumber, message } = JSON.parse( errorItem.message )
				errorObj[ partNumber ] = message
				return errorObj
			}, {} )
	} else {
		var errorMessages = {}
	}

	if ( cart ) {
		cart.getProductQty = ( partNumber ) => {
			return cart
				.items
				.find( ( { product } ) => product.qty )
		}
	}

	const getProductQty = ( partNumber ) => {
		if ( cart ) {
			let cartItem = cart
				.items
				.find( ( { product } ) => product.partNumber == partNumber )
			return cartItem
				? cartItem.qty
				: 0
		} else {
			return NaN
		}
	}

	return {
		cart,
		loading: addLoading || removeLoading || clearLoading || getLoading,
		error: errorMessages,
		addToCart: addToCartWrapper,
		removeFromCart: removeFromCartWrapper,
		getProductQty,
		clearCart
	}
}

const useApplicationHistory = () => {

	const { data, loading, error } = useQuery( GET_APPLICATION_HISTORY, { fetchPolicy: "network-only" } )

	return [
		data
			? data.applicationHistory
			: [],
		loading,
		error
	]
}

const useAddApplicationToHistory = ( onComplete ) => {

	const [applications, setApplications] = useState( [] )
	const [addApplicationToHistory, {
			loading,
			error,
			called
		}
	] = useMutation( ADD_APPLICATION_TO_HISTORY, {
		onCompleted( data ) {

			var event = new CustomEvent( 'updated_vehicles', {
				bubbles: true,
				detail: data.addApplicationToHistory
			} )
			document.dispatchEvent( event )
			setApplications( data.addApplicationToHistory )
			onComplete && process.nextTick( onComplete )
		}
	} );

	const { data, loadingQuery, errorQuery } = useQuery( GET_APPLICATION_HISTORY, {
		skip: called,
		fetchPolicy: "network-only",
		onCompleted: data => data.applicationHistory && setApplications( data.applicationHistory )
	} )

	const addApplicationToHistoryWrapper = ( make, model, subModel, year, isDefault, save ) => {

		addApplicationToHistory( {
			variables: {
				make,
				model,
				subModel,
				year: parseInt( year ),
				isDefault,
				save: save
			}
		} )
	}

	return [
		addApplicationToHistoryWrapper, applications, loading || loadingQuery,
		error || errorQuery
	]
}

const useCheckFitment = ( partNumber, applications ) => {

	//Need this jank because of how I can this method from the Product Page
	//TODO make this better
	applications = applications.some( item => item && Object.keys( item ).length )
		? applications
		: []

	const { data, loading, error } = useQuery( CHECK_FITMENT, {
		skip: !( partNumber && applications && applications.length ),
		variables: {
			partNumber,
			applications: applications.map( application => ( {
				make: application.make,
				model: application.model,
				subModel: application.subModel,
				year: parseInt( application.year ),
				engineSize: application.engineSize
			} ) )
		}
	} );

	return [
		data
			? data.checkFitment
			: [],
		loading,
		error
	]
}

export {
	useProduct,
	useProductApplications,
	useProductApplicationsDetails,
	useProductApplicationsForProduct,
	useRelatedProducts,
	useProductCategories,
	useProductTypeDescriptions,
	useInventoryLevels,
	useGetCart,
	useCart,
	useAddApplicationToHistory,
	useApplicationHistory,
	useCheckFitment
}
